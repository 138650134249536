// Pages/Homepage.jsx
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';

import { products } from '../Data/Products';
import { Arrow, Chevron, Footer, Header, NavScroll, Navbar, SliderPopup } from '../Components/index';
import { eyelit, close, customer1, customer2, customer3, customer4, customer5, customer6, customer7, customer8, customer9, offset, ink, kiosk, rollup, standee, techieonix, whatsappModal, gallery11, gallery12, gallery13, gallery14, gallery21, gallery22, gallery23, gallery24, gallery25, gallery26, gallery27, gallery28, gallery29, gallery31, gallery32, gallery33, gallery34, gallery35, gallery36, gallery37, gallery38, gallery41, gallery42, gallery43, gallery44, gallery51, gallery52, gallery53, gallery54, gallery55, gallery56, gallery61, gallery62, gallery63, broucher, ink001, heroHomeBannerAllProducts, ksk001, xst001, wipes, eye001, rls001, xst001_6, heroHomeStandee, heroHomeRollup, acloudTechie, apkikhidmat, soniJewellers, digital } from '../assets';


const productCategories = [
    { name: 'Ink', img: ink },
    { name: 'Stands', img: standee },
    { name: 'Rollup', img: rollup },
    { name: 'Digital Printing', img: digital },
    { name: 'Offset Printing', img: offset },
    { name: 'Kiosk Table', img: kiosk },
    { name: 'Wipes', img: wipes },
    { name: 'Eyelets', img: eyelit }
]

const projects = [
    {
        name: 'Customer',
        img: gallery11,
        desc: 'Backdrop',
        images: [
            { name: "Customer 1", img: gallery11 },
            { name: "Customer 2", img: gallery12 },
            { name: "Customer 3", img: gallery13 },
            { name: "Customer 4", img: gallery14 }
        ]
    },
    {
        name: 'Ali Dairy Shop',
        img: gallery21,
        desc: 'Panaflex printing',
        images: [
            { name: "Ali Dairy Shop 1", img: gallery21 },
            { name: "Ali Dairy Shop 2", img: gallery22 },
            { name: "Ali Dairy Shop 3", img: gallery23 },
            { name: "Ali Dairy Shop 4", img: gallery24 },
            { name: "Ali Dairy Shop 5", img: gallery25 },
            { name: "Ali Dairy Shop 6", img: gallery26 },
            { name: "Ali Dairy Shop 7", img: gallery27 },
            { name: "Ali Dairy Shop 8", img: gallery28 },
            { name: "Ali Dairy Shop 9", img: gallery29 }
        ]
    },
    {
        name: 'Hani Chemical',
        img: gallery31,
        desc: 'Shop panaflex',
        images: [
            { name: "Hani Chemical 1", img: gallery31 },
            { name: "Hani Chemical 2", img: gallery32 },
            { name: "Hani Chemical 3", img: gallery33 },
            { name: "Hani Chemical 4", img: gallery34 },
            { name: "Hani Chemical 5", img: gallery35 },
            { name: "Hani Chemical 6", img: gallery36 },
            { name: "Hani Chemical 7", img: gallery37 },
            { name: "Hani Chemical 8", img: gallery38 }
        ]
    },
    {
        name: 'Hamza Garments',
        img: gallery41,
        desc: 'Bill board',
        images: [
            { name: "Hamza Garments 1", img: gallery41 },
            { name: "Hamza Garments 2", img: gallery42 },
            { name: "Hamza Garments 3", img: gallery43 },
            { name: "Hamza Garments 4", img: gallery44 }
        ]
    },
    {
        name: 'Karachi Tutors Academy',
        img: gallery51,
        desc: 'Educational Institute banner',
        images: [
            { name: "Karachi Tutors Academy 1", img: gallery51 },
            { name: "Karachi Tutors Academy 2", img: gallery52 },
            { name: "Karachi Tutors Academy 3", img: gallery53 },
            { name: "Karachi Tutors Academy 4", img: gallery54 },
            { name: "Karachi Tutors Academy 5", img: gallery55 },
            { name: "Karachi Tutors Academy 6", img: gallery56 }
        ]
    },
    {
        name: 'Roomi Real state',
        img: gallery61,
        desc: 'Backlit board',
        images: [
            { name: "Roomi Real state 1", img: gallery61 },
            { name: "Roomi Real state 2", img: gallery62 },
            { name: "Roomi Real state 3", img: gallery63 }
        ]
    },
];

const testimonials = [
    {
        name: "acloudtechie.com",
        desc: "Cloud Architect",
        logo: acloudTechie,
        review: "Very affordable and competitive prices for high quality printing products. No disappointment when buying Standee and display stands. Thank you Sk Printing Solutions for the great services.",
    },
    {
        name: "Soni Jewellers",
        desc: "Vendor",
        logo: soniJewellers,
        review: "I was blown away by the quality of the 3D signage provided by this company. The attention to detail and the vibrant colors made the logo stand out beautifully. Highly recommend their services!",
    },
    {
        name: "Techieonix",
        desc: "IT Company",
        logo: techieonix,
        review: "We ordered custom badges and stationery for our corporate event, and the results were fantastic. The print quality was sharp, and the designs were exactly what we envisioned.  Great service!",
    },
    {
        name: "apkikhidmat.com",
        desc: "CEO",
        logo: apkikhidmat,
        review: "I've used their digital printing services for various projects, from stationery to custom gift baskets, and they never disappoint. The quality is always excellent, and Mr Shereyar is always willing to go the extra mile to ensure customer satisfaction. Highly recommended!",
    },
]


export const Homepage = () => {
    const [padding, setPadding] = useState(120);
    const [showProducts, setShowProducts] = useState(true);
    const [showProjects, setShowProjects] = useState(false);
    const [formShown, setFormShown] = useState(true);
    const [noOfProj, setNoOfProj] = useState(3);
    const [popupImages, setpopupImages] = useState([]);
    const productBtn = useRef();
    const projectBtn = useRef();
    const getAQuoteModal = useRef();
    const featuredPop = useRef();
    const navigate = useNavigate();
    const swiperRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const hasShownPopup = localStorage.getItem('hasShownPopup');
            if (formShown && !hasShownPopup && window.pageYOffset >= 700) {
                openPopup(getAQuoteModal);
                setFormShown(false);
                localStorage.setItem('hasShownPopup', 'true');
            }
        };
        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, [formShown]);

    useEffect(() => {
        let width = document.body.offsetWidth
        if (width > 1439) {
            setPadding(padding + ((width - 1440) / 2))
            setNoOfProj(3)
            setShowProducts(false)
        } else if (width > 767) {
            setPadding(60)
            setNoOfProj(2)
        } else {
            setPadding(20)
            setNoOfProj(projects.length)
        }
        window.scrollTo(0, 0)

        // Fetch data from backend and log it to the console
        fetch(`${process.env.REACT_APP_BACKEND_URL}/`)
            .then(response => response.text())
            .then(message => console.log(message));
    }, [])

    const handleSlideChange = (swiper) => {
        if (swiper.slides[swiper.activeIndex].querySelector('img').src.includes(heroHomeBannerAllProducts)) {
            swiper.params.autoplay.delay = 10000; // 10 seconds delay for the first image
        } else {
            swiper.params.autoplay.delay = 2500; // 2.5 seconds delay for other images
        }
        swiper.autoplay.start();
    };

    const toggleSection = (btn, section, funct, isProj) => {
        if (section) {
            btn.current.classList.remove('slide-in-top')
            btn.current.classList.add('slide-in-bottom')
            btn.current.children[0].innerText = 'Show All'
            btn.current.children[1].style.transform = 'rotate(0deg)'
        } else {
            btn.current.classList.remove('slide-in-bottom')
            btn.current.classList.add('slide-in-top')
            btn.current.children[0].innerText = 'Show less'
            btn.current.children[1].style.transform = 'rotate(180deg)'
        }
        funct(!section)

        if (isProj) {
            let work = document.getElementById('work')
            section ? work.style.display = 'none' : work.style.display = 'flex'
        }
    }

    const openPopup = (popup, images) => {
        setpopupImages(images)
        popup.current.showModal()
    }


    return (
        <main>
            {/* Header */}
            <Header />



            {/* Navbar */}
            <Navbar />



            {/* Navbar on scroll */}
            <NavScroll />



            {/* Hero */}
            <section className="sm:h-[590px] relative px-5 sm:px-[--padding-x-sm] xl:px-[--padding-x-lg] center">

                <Swiper
                    spaceBetween={500}
                    loop
                    autoplay={{ delay: 10000, disableOnInteraction: false }} // Initial delay of 10 seconds for the first image
                    modules={[Autoplay]}
                    className="mySwiper sm:!absolute top-0 right-0 sm:w-[350px] xl:w-[700px] h-[300px] sm:h-[590px]"
                    ref={swiperRef}
                    onSlideChange={handleSlideChange}
                >
                    <SwiperSlide className='!flex justify-center sm:justify-end items-center'>
                        <img src={heroHomeBannerAllProducts} alt="ink" className='size-80 xl:size-[600px]' />
                    </SwiperSlide>
                    <SwiperSlide className='!flex justify-center sm:justify-end items-center'>
                        <img src={ink001} alt="ink" className='size-80 xl:size-[600px]' />
                    </SwiperSlide>
                    <SwiperSlide className='!flex justify-center sm:justify-end items-center'>
                        <img src={ksk001} alt="kiosk" className='size-80 xl:size-[600px]' />
                    </SwiperSlide>
                    <SwiperSlide className='!flex justify-center sm:justify-end items-center'>
                        <img src={xst001} alt="standee" className='size-80 xl:size-[600px]' />
                    </SwiperSlide>
                    <SwiperSlide className='!flex justify-center sm:justify-end items-center'>
                        <img src={heroHomeStandee} alt="standee" className='size-80 xl:size-[600px]' />
                    </SwiperSlide>
                    <SwiperSlide className='!flex justify-center sm:justify-end items-center'>
                        <img src={xst001_6} alt="standee" className='size-80 xl:size-[600px]' />
                    </SwiperSlide>
                    <SwiperSlide className='!flex justify-center sm:justify-end items-center'>
                        <img src={eye001} alt="eyelet" className='size-80 xl:size-[600px]' />
                    </SwiperSlide>
                    <SwiperSlide className='!flex justify-center sm:justify-end items-center'>
                        <img src={rls001} alt="rollup" className='size-80 xl:size-[600px]' />
                    </SwiperSlide>
                    <SwiperSlide className='!flex justify-center sm:justify-end items-center'>
                        <img src={heroHomeRollup} alt="rollup" className='size-80 xl:size-[600px]' />
                    </SwiperSlide>
                </Swiper>

                <div className='py-9 sm:p-0 col-span-5 sm:w-[46%] xl:w-1/3 sm:absolute top-1/2 sm:-translate-y-1/2'>
                    <p className='title text-center sm:text-left'>Welcome to SK Printing</p>
                    <p className='mt-3 sm:mt-6 xl:mt-9 text-[#484E4E]'>Boost your business with SK Printing Solution. Explore our range of top-quality inks, standees, and more designed to enhance your marketing. Make your brand stand out with vibrant prints and creative displays. Easy, effective solutions are just a click away. Partner with us today to see the difference.</p>
                    <div className='space-y-4 sm:space-y-0 sm:space-x-3.5 mt-[46px] sm:mt-14'>
                        <Link to='/products' className='button-black block sm:inline-block text-center'>Buy Now</Link>
                        <Link to='/contact-us' className='button-outline block sm:inline-block text-center'>Contact Us</Link>
                    </div>
                </div>

            </section>



            {/* Products */}
            <section className='bg-[#F4EEE5]'>
                <div className='py-9 sm:py-14 xl:py-[--padding-y-lg] center'>
                    <h1 className='sm:px-[--padding-x-sm] xl:px-[--padding-x-lg] max-sm:text-center'>Products</h1>

                    <div className='flex xl:grid grid-cols-4 gap-5 sm:gap-6 xl:gap-8 mt-6 xl:mt-5 px-5 sm:px-[--padding-x-sm] xl:px-[--padding-x-lg] overflow-x-auto'>
                        {productCategories.slice(0, showProducts ? productCategories.length : 4).map((product, i) => (
                            <div key={i} style={{ boxShadow: '0 4px 10px #00000033' }}
                                className={`${i > 4 && showProducts ? 'fade-in' : ''} min-w-60 overflow-hidden cursor-pointer`}
                                onClick={() => navigate(`/products/${product.name}`)}>
                                <img src={product.img} alt={product.name} title={product.name}
                                    className='w-full h-[236px] hover:scale-110 transition-transform duration-300' />
                                <h5 className='bg-white px-5 xl:px-8 py-4 xl:py-5 flex justify-between xl:z-10 xl:relative'>
                                    <span>{product.name}</span>
                                    <span className='cursor-pointer'> <Arrow className='fill-[--black-bg]' /> </span>
                                </h5>
                            </div>
                        ))}
                    </div>

                    <button onClick={() => toggleSection(productBtn, showProducts, setShowProducts)} ref={productBtn} className='button-black flex justify-center items-center mt-8 gap-x-3 mx-auto max-xl:hidden'>
                        <span>View All</span>
                        <Chevron className="fill-white" />
                    </button>
                </div>
            </section>



            {/* Mission */}
            <section className='mission-bg px-5 sm:px-0 py-10 sm:py-[--padding-y-lg] text-white space-y-2 xl:space-y-3'>
                <h1 className='text-center'>Mission</h1>
                <p className='sm:w-[482px] mx-auto sm:pl-40 xl:pl-[300px] box-content text-center sm:text-left'>At SK Printing Solution, we commit to bringing your brand to life with superior printing solutions. We use advanced technology and premium materials to ensure your promotional materials not only meet but exceed expectations, making every impression count.</p>
            </section>



            {/* Featured Products */}
            <section className='px-5 sm:px-[--padding-x-sm] xl:px-[--padding-x-lg] py-9 sm:py-14 xl:py-[--padding-y-lg] space-y-4 sm:space-y-6 xl:space-y-4 center'>

                <div className='flex justify-between items-center'>
                    <h1 className='text-[--black]'>Featured Products</h1>

                    <Link to="/products" className={`sm:flex justify-center items-center gap-x-3 button-outline max-sm:p-0 max-sm:ring-0`}>
                        <span className='max-sm:underline p-sm sm:text-base sm:leading-[--base-height]'>View All</span>
                        <div className='max-sm:hidden -rotate-45'> <Arrow className='size-4 fill-current' /> </div>
                    </Link>
                </div>

                <div className='grid sm:grid-cols-2 xl:grid-cols-4 gap-8 sm:gap-6 xl:gap-8'>

                    {products.slice(0, 4).map(product => (
                        <div key={product.category} className='cursor-pointer'
                            onClick={() => navigate(`/products/${product.category}/${product.items[0].id}`)}>
                            <div className='ring-1 divide-y divide-[--black-2] ring-[--black-2]'>
                                <img
                                    src={product.items[0].img[0]}
                                    alt={product.items[0].name}
                                    title={product.items[0].name}
                                    className='mix-blend-multiply hover:scale-110 transition-transform duration-300'
                                />
                                <div className='px-5 py-3 sm:p-4 flex justify-between items-center'>
                                    <div className='space-y-0.5'>
                                        <h5 className='text-[--black] font-bold'>{product.items[0].name}</h5>
                                        <p className='text-[--black] opacity-80'>{product.items[0].made
                                            || product.items[0].model
                                            || product.items[0].type
                                        }</p>
                                    </div>
                                    <span className='cursor-pointer'> <Arrow className='fill-[--black-bg]' /> </span>
                                </div>
                            </div>
                        </div>
                    ))}

                </div>
            </section>



            {/* Range of quality printing products */}
            <section className='broucher-bg'>
                <div className='pb-9 sm:pb-14 xl:pb-[76px] pt-[68px] sm:pt-[70px] xl:pt-[88px] px-5 sm:pl-[136px] sm:pr-[--padding-x-sm] xl:px-0 text-white sm:flex justify-center gap-x-6 xl:gap-x-8 center'>

                    <h1 className='text-center sm:text-left px-16 sm:p-0 sm:w-[275px] flex-shrink-0'>Range of quality printing products</h1>

                    <div className='xl:w-[635px] space-y-12 sm:space-y-6 xl:space-y-12 mt-8 sm:mt-0'>
                        <p className='opacity-80'>Explore the diverse range of SK Printing Solutions, tailored to elevate your marketing impact. From high-quality inks to eye-catching standees and robust kiosk tables, we have everything your business needs to stand out. Each product is crafted to ensure your brand looks its best. Simple, effective, and ready to boost your presence. Start making a difference with us today.</p>
                        <a href={broucher} target='_blank' className='inline-block button-black bg-white text-black hover:text-white w-full sm:w-auto'>Company Brochure</a>
                    </div>

                </div>
            </section>



            {/* Recent Projects */}
            <section className='xl:flex gap-x-28 py-9 sm:py-14 xl:py-[--padding-y-lg] px-5 sm:px-[--padding-x-sm] xl:px-[--padding-x-lg] text-[--black] center'>

                <h1 className='text-center sm:text-left'>Recent<br className='max-xl:hidden' /> Projects</h1>

                <div className='mt-6 xl:mt-0'>

                    <div className='grid sm:grid-cols-2 xl:grid-cols-3 gap-x-6 xl:gap-x-8 gap-y-3 sm:gap-y-6 xl:gap-y-11'>
                        {projects.slice(0, showProjects ? projects.length : noOfProj).map((project, i) => (
                            <div key={project.name} className={showProjects ?
                                (noOfProj === projects.length ? '' :
                                    (noOfProj === 3 ?
                                        (i > 2 ? 'fade-in' : '') :
                                        (i > 1 ? 'fade-in' : '')
                                    )
                                ) : ''}>

                                <img src={project.img} alt={project.name} title={project.name}
                                    className='cursor-pointer max-sm:mx-auto sm:w-full'
                                    onClick={() => openPopup(featuredPop, project.images)} />

                                <div className='p-4 text-center sm:text-left'>
                                    <h5>{project.name}</h5>
                                    <p>{project.desc}</p>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className='flex gap-x-8 mt-8 sm:mt-6 xl:mt-8'>

                        <button ref={projectBtn}
                            className='button-black hidden sm:flex justify-center items-center gap-x-3'
                            onClick={() => toggleSection(projectBtn, showProjects, setShowProjects, true)}>
                            <span>Load More</span>
                            <Chevron className="fill-white" />
                        </button>

                        <Link to='/gallery' id='work'
                            className={`button-black flex w-full sm:w-auto sm:hidden sm:text-[--black] sm:bg-white sm:ring-1 ring-inset ring-[--black] justify-center items-center gap-x-3 hover:ring-0 hover:text-white ${showProjects ? 'fade-in' : ''}`}>
                            <span>Our Work</span>
                            <Arrow className='size-6 fill-current' />
                        </Link>

                    </div>
                </div>

            </section>



            {/* What They are Saying */}
            <section className='bg-gradient-to-b sm:bg-gradient-to-r from-[--black-bg] from-50% xl:from-40% to-[--green] to-50% xl:to-40%'>

                <div className='max-sm:h-[569px] xl:h-[460px] space-y-10 sm:space-y-6 xl:space-y-0 pt-12 sm:py-14 xl:py-28 sm:px-[--padding-x-sm] xl:px-[--padding-x-lg] xl:flex gap-x-[116px] items-start center'>
                    <h1 className='text-white max-sm:text-center'>What<br className='max-xl:hidden' /> They are<br className='max-xl:hidden' /> Saying</h1>

                    <Swiper
                        pagination={{ clickable: true }}
                        loop={true}
                        modules={[Pagination]}
                        className="mySwiper max-sm:w-[315px] xl:w-[742px] rounded-[10px] bg-white xl:!m-0 home-testimonial"
                        style={{ boxShadow: '-4px 6px 20px #0000001a' }}>

                        {testimonials.map(el => (<SwiperSlide key={el.name}>
                            <div className='sm:flex items-center'>
                                <div className='space-y-3 px-5 sm:px-14 py-6 sm:py-12'>
                                    <img src={el.logo} alt={el.name} title={el.name} className='size-[70px] max-sm:mx-auto' />
                                    <div className='space-y-1 max-sm:text-center'>
                                        <p className='font-semibold text-[#545454]'>{el.name}</p>
                                        <p className='cap-lg text-[--black]'>{el.desc}</p>
                                    </div>
                                </div>

                                <p className='px-6 pt-6 pb-12 sm:py-12'>{el.review}</p>
                            </div>
                        </SwiperSlide>))}
                    </Swiper>

                </div>
            </section>



            {/* Our Valuable clients */}
            <section className='px-5 sm:px-[--padding-x-sm] xl:px-[--padding-x-lg] py-9 sm:py-14 xl:py-[--padding-y-lg] space-y-8 center'>
                <h1 className='max-sm:text-center'>Our Valuable clients</h1>
                <div className="relative w-full">
                    <Swiper
                        navigation={{
                            nextEl: '.next-customers',
                            prevEl: '.prev-customers'
                        }}
                        modules={[Navigation]}
                        loop={true}
                        slidesPerView={2}
                        spaceBetween={32}
                        breakpoints={{
                            768: { slidesPerView: 3 },
                            1440: { slidesPerView: 5 }
                        }}
                        className="mySwiper select-none">
                        <SwiperSlide>
                            <img src={customer1} alt='Alkhidmat' title='Alkhidmat' className='mx-auto w-32 h-20' />
                        </SwiperSlide>

                        <SwiperSlide>
                            <img src={customer2} alt='Dawat e Islami' title='Dawat e Islami' className='mx-auto w-32 h-20' />
                        </SwiperSlide>

                        <SwiperSlide>
                            <img src={customer3} alt='Graphics advertising solutions' title='Graphics advertising solutions' className='mx-auto w-32 h-20' />
                        </SwiperSlide>

                        <SwiperSlide>
                            <img src={customer4} alt='Meezan bank' title='Meezan bank' className='mx-auto w-32 h-20' />
                        </SwiperSlide>

                        <SwiperSlide>
                            <img src={customer5} alt='Printing city' title='Printing city' className='mx-auto w-32 h-20' />
                        </SwiperSlide>

                        <SwiperSlide>
                            <img src={customer6} alt='Print Studio' title='Print Studio' className='mx-auto w-32 h-20' />
                        </SwiperSlide>

                        <SwiperSlide>
                            <img src={customer7} alt='UBL bank' title='UBL bank' className='mx-auto w-32 h-20' />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img
                                src={customer8}
                                alt="acloud Techie"
                                title="acloud Techie"
                                className="mx-auto w-32 h-20"
                            />
                        </SwiperSlide>

                        <SwiperSlide>
                            <img
                                src={customer9}
                                alt="apki khidmat"
                                title="apki khidmat"
                                className="mx-auto w-32 h-20"
                            />
                        </SwiperSlide>

                    </Swiper>
                    <div className="next-customers -rotate-90 -translate-y-1/2 z-[1] size-8 flex items-center justify-center rounded-full hover:bg-[--black] hover:text-white">
                        <Chevron className="fill-current" />
                    </div>
                    <div className="prev-customers rotate-90 -translate-y-1/2 z-[1] size-8 flex items-center justify-center rounded-full hover:bg-[--black] hover:text-white">
                        <Chevron className="fill-current" />
                    </div>
                </div>
            </section>



            {/* Footer */}
            <Footer />



            {/* Get a quote */}
            <dialog ref={getAQuoteModal} className='fixed top-1/2 max-sm:w-[335px] max-xl:w-[396px] p-5 sm:p-6 xl:p-8 space-y-9 xl:space-y-12 rounded-2xl fade-in-top backdrop:opacity-80 backdrop:bg-black'>

                <div className='text-[--black] flex flex-col items-center'>
                    <img src={close} alt='Close icon' className='cursor-pointer self-end'
                        onClick={() => getAQuoteModal.current.close()} />
                    <img src={whatsappModal} alt='Whatsapp icon' className='mx-auto mt-1' />
                    <h2 className='text-center mt-4'>Get a Quote</h2>
                    <p className='text-center mt-1'>Connect with us directly via Whatsapp for a quick quote!</p>
                </div>

                <a href='https://wa.me/+923102806759' target='_blank' className='bg-[--black] px-[34px] py-2.5 text-white hover:bg-[--green] transition-colors inline-block text-center w-full'>Chat Now</a>

            </dialog>



            {/* Recent project popup */}
            <SliderPopup popup={featuredPop} images={popupImages} />

        </main >
    );
}