import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

// Swiper Imports
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';

import { Arrow, Chevron, Header, NavScroll, Navbar, Footer, MessagePopup } from "../Components";
import { products } from '../Data/Products'
import { check, close } from "../assets";

export const SingleProduct = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [showdetails, setShowdetails] = useState(false);
  const [product, setProduct] = useState({});
  const getPricePopup = useRef();
  const thankyouPopup = useRef();
  const productId = useParams();
  const navigate = useNavigate();

  useEffect(() => window.scrollTo(0, 0), [navigate]);

  // Getting the specific product
  useEffect(() => {
    products.forEach(prod =>
      prod.hasOwnProperty('items') ?
        prod.items.forEach(item =>
          item.id === productId.productId && setProduct(item)
        ) :
        prod.item?.id === productId.productId && setProduct(prod.item)
    );
  }, [productId]);

  useEffect(() => {
    const handleScroll = () => window.pageYOffset >= 700 ? setShowdetails(true) : setShowdetails(false);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [showdetails]);

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const name = formData.get('name');
    const phone = formData.get('phone');
    const details = formData.get('details');

    const productInfo = `
Product Name: ${product.name}
${product.model ? `Model: ${product.model}` : `Made: ${product.made}` || `Type: ${product.type}`}
${product.dimension ? `Dimension: ${product.dimension}` : `Quantity: ${product.quantity}`}
Price: PKR ${product.price}
`;

    const productImageURL = window.location.origin + (product.img && product.img[0]);

    const message = `Hello, I am ${name}.
Phone: ${phone}
Details: ${details}

Product Information:
${productInfo}
Product Image: ${productImageURL}
`;

    const encodedMessage = encodeURIComponent(message);
    const whatsappURL = `https://wa.me/923102806759?text=${encodedMessage}`;

    window.open(whatsappURL, '_blank');

    e.target.reset();
    getPricePopup.current.close();
    thankyouPopup.current.showModal();
  };

  return (
    <main>
      {/* Header */}
      <Header />

      {/* Navbar */}
      <Navbar />

      {/* Navbar on scroll */}
      <NavScroll />

      {/* Product */}
      <section className='px-5 sm:px-[--padding-x-sm] xl:px-[--padding-x-lg] py-9 sm:pt-9 sm:pb-12 xl:py-12 space-y-3 center'>

        <div className='flex gap-x-2'>
          <Link to='/products' className='flex items-center gap-x-2 group'>
            <span className='group-hover:underline'>All Products</span>
            <div className='-rotate-90'> <Chevron className="fill-[--black]" /> </div>
          </Link>

          <Link to={`/products/${product.category}`} className='flex items-center gap-x-2 group'>
            <span className='group-hover:underline'>{product.category}</span>
            <div className='-rotate-90'> <Chevron className="fill-[--black]" /> </div>
          </Link>

          <div> {product.name}</div>
        </div>

        <div className="sm:grid grid-cols-2 gap-6 xl:gap-8 max-sm:space-y-6">
          <div className='space-y-6'>
            <Swiper
              loop={true}
              spaceBetween={10}
              thumbs={{ swiper: thumbsSwiper }}
              modules={[FreeMode, Navigation, Thumbs]}
              className="mySwiper2">

              {product.img?.map(image =>
                <SwiperSlide key={image}> <img src={image} alt={product.name} className='w-full' /> </SwiperSlide>
              )}

            </Swiper>

            <Swiper
              onSwiper={setThumbsSwiper}
              loop={true}
              breakpoints={{
                1440: { spaceBetween: 20 },
                768: { spaceBetween: 12 },
                0: { spaceBetween: 8 }
              }}
              slidesPerView={4}
              modules={[FreeMode, Navigation, Thumbs]}
              className="mySwiper"
              wrapperClass="justify-center"
            >

              {product.img?.map(image =>
                <SwiperSlide key={image} className='border !w-fit cursor-pointer'>
                  <img src={image} alt={product.name} className='size-12 sm:size-[--padding-x-sm] xl:size-20' />
                </SwiperSlide>
              )}

            </Swiper>
          </div>

          <div className='space-y-4 sm:space-y-6'>
            <h1>{product.description}</h1>

            <div className='space-y-1.5 sm:space-y-2'>
              <div className='flex gap-x-1.5'>
                <span className='font-semibold'>Product Name:</span>
                <span>{product.name}</span>
              </div>

              <div className='flex gap-x-1.5'>
                <span className='font-semibold'>{product.model ? "Model:" : "Made: " || "Type: "}</span>
                <span>{product.model || product.made || product.type}</span>
              </div>

              {(product.dimension && product.dimension != null) && (
                <div className='flex gap-x-1.5'>
                  <span className='font-semibold'>Dimension:</span>
                  <span>{product.dimension}</span>
                </div>
              )}
              {product.quantity && (
                <div className='flex gap-x-1.5'>
                  <span className='font-semibold'>Quantity:</span>
                  <span>{product.quantity}</span>
                </div>
              )}
            </div>

            <h2>{product.price !== "Custom" && "PKR"} {product.price} {product.price === "Custom" && "Price"}</h2>

            <button className='button-black' onClick={() => getPricePopup.current.showModal()}>Get Latest Price</button>
          </div>
        </div>

      </section>

      {/* Product Details: */}
      <section className="px-5 sm:px-[--padding-x-sm] xl:px-[--padding-x-lg] sm:pb-14 xl:pb-[--padding-y-lg] xl:flex items-start gap-x-5 center">
        <div className="space-y-4 sm:space-y-6">

          <div className="space-y-4 xl:space-y-6">
            <h2 className="text-[--black]">Product Details:</h2>

            <ul>
              {product.details?.map(detail => (
                <li key={detail}><span className='text-[--green] font-bold'>✓</span> {detail}</li>
              ))}
            </ul>
          </div>

          <div>
            {product.img?.map(image =>
              <img key={image} src={image} alt={product.name} className="size-full sm:size-[648px] xl:size-[800px]" />
            )}
          </div>
        </div>

        {showdetails && <div className="w-[379px] space-y-6 p-6 text-[--black] border border-[--black] sticky top-28 slide-in-bottom max-xl:hidden">
          <h2>{product.description}</h2>
          <ul className="space-y-2">
            <li>
              <span className="font-semibold">Product Name:</span> {product.name}
            </li>
            <li>
              <span className="font-semibold">{product.model ? "Model:" : "Made: " || "Type: "}</span> {product.model || product.made || product.type}
            </li>
            {(product.dimension && product.dimension != null) && (
              <li>
                <span className='font-semibold'>Dimension:</span> {product.dimension}
              </li>
            )}
            {product.quantity && (
              <li>
                <span className='font-semibold'>Quantity:</span> {product.quantity}
              </li>
            )}

          </ul>
          <h2>{product.price !== "Custom" && "PKR"} {product.price} {product.price === "Custom" && "Price"}</h2>
          <button className="button-black" onClick={() => getPricePopup.current.showModal()}>Get Latest Price</button>
        </div>}
      </section>

      {/* Other Products */}
      <section className='px-5 sm:px-[--padding-x-sm] xl:px-[--padding-x-lg] py-9 sm:py-14 xl:py-[--padding-y-lg] space-y-4 sm:space-y-6 xl:space-y-4 center'>

        <div className='flex justify-between items-center'>
          <h1 className='text-[--black]'>Featured Products</h1>

          <Link to="/products" className={`sm:flex justify-center items-center gap-x-3 button-outline max-sm:p-0 max-sm:ring-0`}>
            <span className='max-sm:underline p-sm sm:text-base sm:leading-[--base-height]'>View All</span>
            <div className='max-sm:hidden -rotate-45'> <Arrow className='size-4 fill-current' /> </div>
          </Link>
        </div>

        <div className='grid sm:grid-cols-2 xl:grid-cols-4 gap-8 sm:gap-6 xl:gap-8'>

          {products.slice(0, 4).map(product => (
            <div key={product.category} className='cursor-pointer'
              onClick={() => navigate(`/products/${product.category}/${product.items[0].id}`)}>
              <div className='ring-1 divide-y divide-[--black-2] ring-[--black-2]'>
                <img src={product.items[0].img[0]} alt={product.items[0].name} title={product.items[0].name} className='mix-blend-multiply hover:scale-110 transition-transform duration-300' />
                <div className='px-5 py-3 sm:p-4 flex justify-between items-center'>
                  <div className='space-y-0.5'>
                    <h5 className='text-[--black] font-bold'>{product.items[0].name}</h5>
                    <p className='text-[--black] opacity-80'>{product.items[0].made
                      || product.items[0].model
                      || product.items[0].type
                    }</p>
                  </div>
                  <span className='cursor-pointer'> <Arrow className='fill-[--black-bg]' /> </span>
                </div>
              </div>
            </div>
          ))}

        </div>
      </section>

      {/* Footer */}
      <Footer />




      {/* Get latest price */}
      <dialog ref={getPricePopup} className="fixed top-1/2 rounded-[10px] sm:rounded-[15px] fade-in-top backdrop:opacity-80 backdrop:bg-black my-0 w-[335px] sm:w-[648px] xl:w-[900px]">
        <div className="p-5 sm:p-0 sm:flex">
          <img src={close} alt='Close icon' className='absolute right-5 sm:right-12 top-6 xl:top-8 cursor-pointer' onClick={() => getPricePopup.current.close()} />

          <div className="pb-5 sm:p-6 xl:p-8 flex sm:flex-col gap-3 mt-7 sm:mt-14">
            <img src={Array.isArray(product.img) && product.img[0]} alt={product.name} className="size-[100px] sm:size-60" />
            <div className="space-y-2 sm:space-y-3">
              <h3 className="text-[--black]">{product.description}</h3>
              <ul className="space-y-1 sm:space-y-2">
                <li className="space-x-1.5">
                  <span className="inline-block font-semibold">Product Name:</span>
                  <span className="inline-block">{product.name}</span>
                </li>
                <li className="space-x-1.5">
                  <span className='font-semibold'>{product.model ? "Model:" : "Made: " || "Type: "}</span>
                  <span>{product.model || product.made || product.type}</span>
                </li>
                {(product.dimension && product.dimension != null) && (
                  <li className="space-x-1.5">
                    <span className="inline-block font-semibold">Dimension:</span>
                    <span className="inline-block">{product.dimension}</span>
                  </li>
                )}
                {product.quantity && (
                  <li className="space-x-1.5">
                    <span className="inline-block font-semibold">Quantity:</span>
                    <span className="inline-block">{product.quantity}</span>
                  </li>
                )}
              </ul>
            </div>
          </div>

          <form className="max-sm:border-t sm:border-l pt-5 sm:p-6 xl:p-8 space-y-9 sm:space-y-6 xl:space-y-12" onSubmit={handleSubmit}>
            <div className="space-y-3 sm:space-y-6 sm:mt-14">
              <p className="text-[--black]">Get Latest Price and details on WhatsApp quickly</p>
              <div className="space-y-2 sm:space-y-3">
                <input type="text" name="name" className="input-border px-3 py-2 w-full" placeholder="Name" required />
                <input type="tel" name="phone" className="input-border px-3 py-2 w-full" placeholder="Phone" required />
                <textarea name="details" className="input-border px-3 py-2 w-full" rows="4" placeholder="Requirement details" required />
              </div>
            </div>

            <button type="submit" className="button-black">Submit</button>
          </form>
        </div>
      </dialog>

      

      {/* Thank you */}
      <MessagePopup
        popup={thankyouPopup}
        icon={check}
        heading="Thank you"
        message="Your details have been submitted"
      />

    </main>
  );
};
