import React from 'react'

export const Location = ({ fill }) => {
    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M8.5 0C5.191 0 2.5 2.691 2.5 6C2.5 10.159 7.916 15.623 8.146 15.854C8.24009 15.9475 8.36735 16 8.5 16C8.63265 16 8.75991 15.9475 8.854 15.854C9.084 15.623 14.5 10.159 14.5 6C14.5 2.691 11.809 0 8.5 0ZM8.5 14.777C7.306 13.499 3.5 9.177 3.5 6C3.5 3.243 5.743 1 8.5 1C11.257 1 13.5 3.243 13.5 6C13.5 9.174 9.694 13.499 8.5 14.777Z" />
            <path d="M8.5 3C6.846 3 5.5 4.346 5.5 6C5.5 7.654 6.846 9 8.5 9C10.154 9 11.5 7.654 11.5 6C11.5 4.346 10.154 3 8.5 3ZM8.5 8C7.397 8 6.5 7.103 6.5 6C6.5 4.897 7.397 4 8.5 4C9.603 4 10.5 4.897 10.5 6C10.5 7.103 9.603 8 8.5 8Z" />
        </svg>

    )
}