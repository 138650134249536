import hero_home_banner_all_products from './hero-home-banner-all-products.jpg'
import img_aboutCta from './about-cta.jpg';
import img_aboutHeroBelow from './hero-about-detail.png'
import img_aboutHeroFront from './hero-about-front.png'
import img_aboutService1 from './about-service-1.svg';
import img_aboutService2 from './about-service-2.svg';
import img_aboutService3 from './about-service-3.svg';
import img_aboutService4 from './about-service-4.svg';
import img_aboutService5 from './about-service-5.svg';
import img_aboutStorybg from './about-story-bg.png';
import img_acloudTechie from './acloudTechie.jpg'
import img_apkikhidmat from './apkikhidmat.jpg'
import img_check from './check.svg';
import img_closeWhite from './close-white.svg';
import img_close from './close.svg';
import img_cross from './cross.svg';
import img_customer1 from './customer-1.jpg';
import img_customer2 from './customer-2.png';
import img_customer3 from './customer-3.jpg';
import img_customer4 from './customer-4.jpg';
import img_customer5 from './customer-5.jpg';
import img_customer6 from './customer-6.jpg';
import img_customer7 from './customer-7.jpg';
import img_customer8 from './customer-8.jpg';
import img_customer9 from './customer-9.jpg';
import img_digital from './digital.jpg'
import img_dp001 from './dp001.jpg';
import img_dp002 from './dp002.jpg';
import img_ecoSolvent from './eco-solvent.jpg';
import img_eye001 from './eye001.jpg';
import img_eyelit from './eyelit.jpg';
import img_gallery11 from "./gallery-1.1.jpg";
import img_gallery12 from "./gallery-1.2.jpg";
import img_gallery13 from "./gallery-1.3.jpg";
import img_gallery14 from "./gallery-1.4.jpg";
import img_gallery21 from "./gallery-2.1.jpg";
import img_gallery22 from "./gallery-2.2.jpg";
import img_gallery23 from "./gallery-2.3.jpg";
import img_gallery24 from "./gallery-2.4.jpg";
import img_gallery25 from "./gallery-2.5.jpg";
import img_gallery26 from "./gallery-2.6.jpg";
import img_gallery27 from "./gallery-2.7.jpg";
import img_gallery28 from "./gallery-2.8.jpg";
import img_gallery29 from "./gallery-2.9.jpg";
import img_gallery31 from "./gallery-3.1.jpg";
import img_gallery32 from "./gallery-3.2.jpg";
import img_gallery33 from "./gallery-3.3.jpg";
import img_gallery34 from "./gallery-3.4.jpg";
import img_gallery35 from "./gallery-3.5.jpg";
import img_gallery36 from "./gallery-3.6.jpg";
import img_gallery37 from "./gallery-3.7.jpg";
import img_gallery38 from "./gallery-3.8.jpg";
import img_gallery41 from "./gallery-4.1.jpg";
import img_gallery42 from "./gallery-4.2.jpg";
import img_gallery43 from "./gallery-4.3.jpg";
import img_gallery44 from "./gallery-4.4.jpg";
import img_gallery51 from "./gallery-5.1.jpg";
import img_gallery52 from "./gallery-5.2.jpg";
import img_gallery53 from "./gallery-5.3.jpg";
import img_gallery54 from "./gallery-5.4.jpg";
import img_gallery55 from "./gallery-5.5.jpg";
import img_gallery56 from "./gallery-5.6.jpg";
import img_gallery61 from "./gallery-6.1.jpg";
import img_gallery62 from "./gallery-6.2.jpg";
import img_gallery63 from "./gallery-6.3.jpg";
import img_gallery71 from "./gallery-7.1.jpg";
import img_gallery72 from "./gallery-7.2.jpg";
import img_gallery73 from "./gallery-7.3.jpg";
import img_gallery81 from "./gallery-8.1.jpg";
import img_gallery82 from "./gallery-8.2.jpg";
import img_gallery91 from "./gallery-9.1.jpg";
import img_gallery92 from "./gallery-9.2.jpg";
import img_gallery101 from "./gallery-10.1.jpg";
import img_gallery102 from "./gallery-10.2.jpg";
import img_gallery103 from "./gallery-10.3.jpg";
import img_gallery104 from "./gallery-10.4.jpg";
import img_gallery105 from "./gallery-10.5.jpg";
import img_gallery106 from "./gallery-10.6.jpg";
import img_gallery111 from "./gallery-11.1.jpg";
import img_gallery121 from "./gallery-12.1.jpg";
import img_gallery122 from "./gallery-12.2.jpg";
import img_gallery131 from "./gallery-13.1.jpg";
import img_gallery141 from "./gallery-14.1.jpg";
import img_gallery151 from "./gallery-15.1.jpg";
import img_gallery161 from "./gallery-16.1.jpg";
import img_gallery162 from "./gallery-16.2.jpg";
import img_gallery171 from "./gallery-17.1.jpg";
import img_heroAbout from "./hero-about.png";
import img_heroContact from './hero-contact.png';
import img_heroGallery from "./hero-gallery.png";
import img_heroHomeRollup from "./hero-home-rollup.jpg"
import img_heroHomeStandee from "./hero-home-standee.jpg"
import img_ink from './ink.jpg';
import img_ink001 from './ink001.jpg'
import img_ink002 from './ink002.jpg'
import img_ink002_1 from './ink002-1.jpg'
import img_ink002_2 from './ink002-2.jpg'
import img_ink002_3 from './ink002-3.jpg'
import img_ink002_4 from './ink002-4.jpg'
import img_ink004 from './ink004.jpg'
import img_ink004_1 from './ink004-1.jpg'
import img_ink004_2 from './ink004-2.jpg'
import img_ink004_3 from './ink004-3.jpg'
import img_ink004_4 from './ink004-4.jpg'
import img_ink006 from './ink006.jpg'
import img_ink008 from './ink008.jpg'
import img_ink010 from './ink010.jpg'
import img_ink010_1 from './ink010-1.jpg'
import img_ink010_2 from './ink010-2.jpg'
import img_ink010_3 from './ink010-3.jpg'
import img_ink010_4 from './ink010-4.jpg'
import img_ink012 from './ink012.jpg'
import img_ink012_1 from './ink012-1.jpg'
import img_ink014 from './ink014.jpg'
import img_insta1 from './insta-1.png';
import img_insta2 from './insta-2.png';
import img_insta3 from './insta-3.png';
import img_insta4 from './insta-4.png';
import img_insta5 from './insta-5.png';
import img_kiosk from './kiosk.jpg';
import img_ksk001 from './ksk001.jpg';
import img_logoFooter from './logo-footer.png';
import img_navMenu from './nav-menu.svg';
import img_navMinus from './nav-minus.svg';
import img_navPlus from './nav-plus.svg';
import img_offset from './offset.jpg'
import img_op001 from './op001.jpg'
import img_op001_1 from './op001-1.jpg'
import img_op001_2 from './op001-2.jpg'
import img_op002 from './op002.jpg'
import img_op002_1 from './op002-1.jpg'
import img_op003 from './op003.jpg'
import img_op003_1 from './op003-1.jpg'
import img_op003_2 from './op003-2.jpg'
import img_op004 from './op004.jpg'
import img_popupDownload from "./popup-download.svg";
import img_popupFacebook from "./popup-facebook.svg";
import img_popupInstagram from "./popup-instagram.svg";
import img_rollup from "./rollup.jpg";
import img_rls001 from "./rls001.jpg";
import img_rls001_1 from "./rls001-1.jpg";
import img_rls002 from "./rls001.jpg";
import img_rls002_1 from "./rls001-1.jpg";
import img_share from './share.svg';
import img_soniJewellers from './soniJewellers.jpg'
import img_standee from './standee.jpg';
import img_star from './star.svg';
import img_techieonix from './techieonix.png';
import img_whatsappHover from './whatsapp-hover.svg';
import img_whatsappModal from './whatsapp-modal.svg';
import img_whatsapp from './whatsapp.svg';
import img_wipes from './wipes.jpg'
import img_wps001 from './wps001.jpg';
import img_xst001_1 from "./xst001-1.jpg";
import img_xst001_2 from "./xst001-2.jpg";
import img_xst001_3 from "./xst001-3.jpg";
import img_xst001_4 from "./xst001-4.jpg";
import img_xst001_5 from "./xst001-5.jpg";
import img_xst001_6 from "./xst001-6.jpg";
import img_xst001 from "./xst001.jpg";
import img_xst002 from "./xst002.jpg";
import img_xst003_1 from "./xst003-1.jpg";
import img_xst003_2 from "./xst003-2.jpg";
import img_xst003_3 from "./xst003-3.jpg";
import img_xst003 from "./xst003.jpg";
import img_zoomIn from './zoom-in.svg';
import pdf_broucher from './broucher.pdf'
import not_found_svg from './not-found-icon.svg'


export const aboutCta = img_aboutCta,
    aboutHeroBelow = img_aboutHeroBelow,
    aboutHeroFront = img_aboutHeroFront,
    aboutService1 = img_aboutService1,
    aboutService2 = img_aboutService2,
    aboutService3 = img_aboutService3,
    aboutService4 = img_aboutService4,
    aboutService5 = img_aboutService5,
    aboutStorybg = img_aboutStorybg,
    acloudTechie = img_acloudTechie,
    apkikhidmat = img_apkikhidmat,
    broucher = pdf_broucher,
    check = img_check,
    closeWhite = img_closeWhite,
    close = img_close,
    cross = img_cross,
    customer1 = img_customer1,
    customer2 = img_customer2,
    customer3 = img_customer3,
    customer4 = img_customer4,
    customer5 = img_customer5,
    customer6 = img_customer6,
    customer7 = img_customer7,
    customer8 = img_customer8,
    customer9 = img_customer9,
    digital = img_digital,
    dp001 = img_dp001,
    dp002 = img_dp002,
    ecoSolvent = img_ecoSolvent,
    eye001 = img_eye001,
    eyelit = img_eyelit,
    gallery11 = img_gallery11,
    gallery12 = img_gallery12,
    gallery13 = img_gallery13,
    gallery14 = img_gallery14,
    gallery21 = img_gallery21,
    gallery22 = img_gallery22,
    gallery23 = img_gallery23,
    gallery24 = img_gallery24,
    gallery25 = img_gallery25,
    gallery26 = img_gallery26,
    gallery27 = img_gallery27,
    gallery28 = img_gallery28,
    gallery29 = img_gallery29,
    gallery31 = img_gallery31,
    gallery32 = img_gallery32,
    gallery33 = img_gallery33,
    gallery34 = img_gallery34,
    gallery35 = img_gallery35,
    gallery36 = img_gallery36,
    gallery37 = img_gallery37,
    gallery38 = img_gallery38,
    gallery41 = img_gallery41,
    gallery42 = img_gallery42,
    gallery43 = img_gallery43,
    gallery44 = img_gallery44,
    gallery51 = img_gallery51,
    gallery52 = img_gallery52,
    gallery53 = img_gallery53,
    gallery54 = img_gallery54,
    gallery55 = img_gallery55,
    gallery56 = img_gallery56,
    gallery61 = img_gallery61,
    gallery62 = img_gallery62,
    gallery63 = img_gallery63,
    gallery71 = img_gallery71,
    gallery72 = img_gallery72,
    gallery73 = img_gallery73,
    gallery81 = img_gallery81,
    gallery82 = img_gallery82,
    gallery91 = img_gallery91,
    gallery92 = img_gallery92,
    gallery101 = img_gallery101,
    gallery102 = img_gallery102,
    gallery103 = img_gallery103,
    gallery104 = img_gallery104,
    gallery105 = img_gallery105,
    gallery106 = img_gallery106,
    gallery111 = img_gallery111,
    gallery121 = img_gallery121,
    gallery122 = img_gallery122,
    gallery131 = img_gallery131,
    gallery141 = img_gallery141,
    gallery151 = img_gallery151,
    gallery161 = img_gallery161,
    gallery162 = img_gallery162,
    gallery171 = img_gallery171,
    heroAbout = img_heroAbout,
    heroContact = img_heroContact,
    heroGallery = img_heroGallery,
    heroHomeRollup = img_heroHomeRollup,
    heroHomeStandee = img_heroHomeStandee,
    heroHomeBannerAllProducts = hero_home_banner_all_products,
    ink = img_ink,
    ink001 = img_ink001,
    ink002 = img_ink002,
    ink002_1 = img_ink002_1,
    ink002_2 = img_ink002_2,
    ink002_3 = img_ink002_3,
    ink002_4 = img_ink002_4,
    ink004 = img_ink004,
    ink004_1 = img_ink004_1,
    ink004_2 = img_ink004_2,
    ink004_3 = img_ink004_3,
    ink004_4 = img_ink004_4,
    ink006 = img_ink006,
    ink008 = img_ink008,
    ink010 = img_ink010,
    ink010_1 = img_ink010_1,
    ink010_2 = img_ink010_2,
    ink010_3 = img_ink010_3,
    ink010_4 = img_ink010_4,
    ink012 = img_ink012,
    ink012_1 = img_ink012_1,
    ink014 = img_ink014,
    insta1 = img_insta1,
    insta2 = img_insta2,
    insta3 = img_insta3,
    insta4 = img_insta4,
    insta5 = img_insta5,
    kiosk = img_kiosk,
    ksk001 = img_ksk001,
    logoFooter = img_logoFooter,
    navMenu = img_navMenu,
    navMinus = img_navMinus,
    navPlus = img_navPlus,
    notFoundSVG = not_found_svg,
    offset = img_offset,
    op001 = img_op001,
    op001_1 = img_op001_1,
    op001_2 = img_op001_2,
    op002 = img_op002,
    op002_1 = img_op002_1,
    op003 = img_op003,
    op003_1 = img_op003_1,
    op003_2 = img_op003_2,
    op004 = img_op004,
    popupDownload = img_popupDownload,
    popupFacebook = img_popupFacebook,
    popupInstagram = img_popupInstagram,
    rollup = img_rollup,
    rls001 = img_rls001,
    rls001_1 = img_rls001_1,
    rls002 = img_rls002,
    rls002_1 = img_rls002_1,
    share = img_share,
    soniJewellers = img_soniJewellers,
    standee = img_standee,
    star = img_star,
    techieonix = img_techieonix,
    whatsappHover = img_whatsappHover,
    whatsappModal = img_whatsappModal,
    whatsapp = img_whatsapp,
    wipes = img_wipes,
    wps001 = img_wps001,
    xst001_1 = img_xst001_1,
    xst001_2 = img_xst001_2,
    xst001_3 = img_xst001_3,
    xst001_4 = img_xst001_4,
    xst001_5 = img_xst001_5,
    xst001_6 = img_xst001_6,
    xst001 = img_xst001,
    xst002 = img_xst002,
    xst003_1 = img_xst003_1,
    xst003_2 = img_xst003_2,
    xst003_3 = img_xst003_3,
    xst003 = img_xst003,
    zoomIn = img_zoomIn