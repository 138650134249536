import React from 'react'

import { whatsapp, whatsappHover } from '../assets'


export const Whatsapp = () => {
    return (
        <a href='https://wa.me/+923102806759' target='_blank' className='fixed right-5 sm:right-8 bottom-9 sm:bottom-8 group z-10' style={{ filter: 'drop-shadow(0 4px 10px #0000004d)' }} title='Go to Whatsapp'>
            <img src={whatsapp} alt='Whatsapp icon' className='group-hover:hidden block' />
            <img src={whatsappHover} alt='Whatsapp icon' className='group-hover:block hidden' />
        </a>
    )
}