import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Arrow, Header, NavScroll, Navbar, Footer, Chevron, FollowInstagram, Search } from "../Components/index";
import { products } from "../Data/Products";


export const ProductsByCategory = () => {
  // State management for "Load More" functionality
  const [showProducts, setShowProducts] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [productCategory, setProductCategory] = useState([]);
  const productBtn = useRef();
  const navigate = useNavigate();
  const { category } = useParams();


  // useEffect for initial setup
  useEffect(() => {
    document.body.offsetWidth < 768 ? setShowProducts(true) : setShowProducts(false);
    window.scrollTo(0, 0);

    const updatedProducts = products
      .filter(p => p.category === category)
      .flatMap(p => p.items || p.item ? p.items || p.item : []);
    setProductCategory(updatedProducts)
  }, []);


  const filteredProducts = productCategory.filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()) && item)
  const shouldShowLoadMore = productCategory.length > 12;


  return (
    <main>
      {/* Header */}
      <Header />


      {/* Navbar */}
      <Navbar />

      <NavScroll />




      {/* Products */}
      <section className="px-5 sm:px-[--padding-x-sm] xl:px-[--padding-x-lg] py-9 sm:pt-9 xl:pt-12 sm:pb-14 xl:pb-[--padding-y-lg] space-y-4 sm:space-y-6 xl:space-y-10 center">

        <div className="space-y-6 xl:space-y-8">
          <div className="max-xl:space-y-4 xl:flex justify-between items-center">

            <h1>{productCategory[0]?.category}</h1>

            <div className='relative'>
              <input type="text"
                className='py-2 pl-5 pr-14 input-border w-full'
                placeholder='Search products' onChange={e => setSearchTerm(e.target.value)}
              />
              <div className='absolute top-1/2 right-4 -translate-y-1/2'> {" "} <Search className="fill-[--black-bg]" /> {" "} </div>
            </div>
          </div>

          <div className="grid sm:grid-cols-2 xl:grid-cols-4 gap-8 sm:gap-6 xl:gap-8">

            {filteredProducts.length > 0 ? (
              (filteredProducts.slice(0, showProducts ? filteredProducts.length : 12)
                .map((product, i) => (
                  <div key={product.id}
                    className={`${showProducts && i > 11 ? 'fade-in' : ''} border divide-y cursor-pointer overflow-hidden`}
                    onClick={() => navigate(`/products/${product.category}/${product.id}`)}
                  >

                    <img src={product.img[0]} alt={product.name} title={product.name}
                      className="hover:scale-110 transition-transform duration-300 peer"
                    />

                    <div className="p-4 flex justify-between items-center z-10 xl:relative peer-hover:z-10 peer-hover:relative bg-white">
                      <div>
                        <h5>{product.name}</h5>
                        <p className="text-[#232b2bcc]">{product.model || product.made}</p>
                      </div>
                      <span> <Arrow className="fill-[--black-bg]" /> </span>
                    </div>

                  </div>
                ))
              )
            ) : (
              <div className="col-span-full text-center text-2xl font-semibold">No Product Found</div>
            )}
          </div>

        </div>

        {shouldShowLoadMore && (
          <button
            onClick={() => setShowProducts(!showProducts)}
            ref={productBtn}
            className='button-outline flex justify-center items-center gap-x-3 mx-auto max-sm:hidden'
          >
            <span>{showProducts ? 'View Less' : 'Load More'}</span>
            <Chevron className={`fill-current ${showProducts ? 'rotate-180' : 'rotate-0'}`} />
          </button>
        )}

      </section>




      {/* Follow Instagram */}
      <FollowInstagram backGround="#F4EEE5" />




      {/* Footer */}
      <Footer />
    </main >
  );
};
