import React from 'react'

export const Logo = ({ className }) => {
    return (
        <svg width="170" height="39" className={className} viewBox="0 0 170 39" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_457_1057)">
                <path d="M55.0521 21.6329C54.1938 20.7798 53.826 19.561 53.826 18.0376H57.1978C57.1978 19.1954 57.443 19.9267 57.9334 20.2313C58.4239 20.536 59.0369 20.7188 59.8339 20.7188C60.6309 20.7188 61.3052 20.597 61.7344 20.3532C62.2248 20.1095 62.47 19.7438 62.47 19.2563C62.47 18.647 62.1635 18.2813 61.4891 18.0376C60.8148 17.7938 59.8952 17.611 58.7304 17.3673C56.8912 17.0017 55.6038 16.5142 54.8681 16.0267C54.1325 15.5392 53.7646 14.686 53.7646 13.5892C53.7646 12.4923 54.1938 11.5173 55.0521 10.7251C55.9103 9.93291 57.443 9.50635 59.7726 9.50635C62.1022 9.50635 63.6348 9.93291 64.4931 10.847C65.3514 11.761 65.7805 12.9188 65.7805 14.3813H62.4087C62.4087 13.2845 62.1635 12.5532 61.6731 12.2485C61.1826 11.9438 60.5696 11.822 59.7726 11.822C58.9756 11.822 58.3013 11.9438 57.8108 12.1876C57.3204 12.4313 57.0751 12.797 57.0751 13.2235C57.0751 13.8329 57.443 14.2595 58.1173 14.5032C58.7917 14.747 59.7113 14.9298 60.8761 15.1735C62.7766 15.5392 64.1253 16.0267 64.7996 16.4532C65.474 16.8798 65.8418 17.7329 65.8418 18.8298C65.8418 19.9876 65.4127 20.9626 64.6157 21.8157C63.8187 22.6079 62.2248 23.0345 59.8952 23.0345C57.5043 22.9126 55.9103 22.486 55.0521 21.6329Z" />
                <path d="M67.4971 9.50635H70.8689V15.2954L75.2216 9.50635H79.0225L73.9341 16.1485L79.0225 22.7907H75.2216L70.8689 17.0017V22.7907H67.4971V9.50635Z" />
                <path d="M85.2139 9.50635H92.4479C94.1032 9.50635 95.2067 9.99385 95.7584 10.9079C96.3102 11.822 96.5554 12.9798 96.5554 14.3813C96.5554 15.7829 96.3102 16.9407 95.7584 17.8548C95.2067 18.7688 94.1032 19.2563 92.4479 19.2563H88.5857V22.7907H85.2139V9.50635ZM91.467 17.0017C92.0801 17.0017 92.5705 16.8188 92.8157 16.3923C93.061 15.9657 93.1836 15.2954 93.1836 14.3813C93.1836 13.4673 93.061 12.797 92.7544 12.3704C92.5092 11.9438 92.0188 11.761 91.4057 11.761H88.5857V17.0017H91.467Z" />
                <path d="M98.0889 11.1517H100.664L101.399 13.1626V22.8517H98.0889V11.1517ZM105.078 14.0157C104.771 13.5892 104.281 13.3454 103.668 13.3454C102.993 13.3454 102.442 13.5892 102.012 14.0767C101.583 14.5642 101.399 15.1126 101.399 15.722L100.112 14.9907C100.112 13.8938 100.541 12.9798 101.461 12.1876C102.38 11.3954 103.484 11.0298 104.955 11.0298C106.488 11.0298 107.591 11.5173 108.143 12.4313C108.695 13.4063 109.001 14.8688 109.001 16.8798H105.691C105.629 15.4173 105.446 14.4423 105.078 14.0157Z" />
                <path d="M114.518 7.61719V9.93281H111.146V7.61719H114.518ZM111.146 11.1516H114.518V22.8516H111.146V11.1516Z" />
                <path d="M116.664 11.1518H119.239L119.975 13.1018V22.8518H116.664V11.1518ZM124.327 16.6361C124.327 15.3564 124.143 14.4424 123.837 14.0158C123.53 13.5283 123.04 13.3455 122.365 13.3455C121.691 13.3455 121.078 13.6502 120.588 14.1986C120.097 14.7471 119.852 15.4783 119.852 16.3924L118.197 15.6611C118.197 14.6252 118.687 13.5893 119.668 12.6143C120.649 11.6393 121.936 11.0908 123.53 11.0908C125.063 11.0908 126.105 11.5783 126.657 12.6143C127.209 13.6502 127.515 14.9908 127.515 16.6361V22.9127H124.205V16.6361H124.327Z" />
                <path d="M132.542 22.364C131.806 21.9374 131.439 21.1452 131.439 19.9874V13.4062H128.741V11.1515H131.439V8.47021H134.81V11.1515H138.427V13.4062H134.81V19.1952C134.81 19.8046 134.933 20.2312 135.24 20.414C135.485 20.5968 135.914 20.7187 136.343 20.7187C136.834 20.7187 137.324 20.6577 137.753 20.4749V22.6687C136.956 22.8515 136.098 22.9733 135.178 22.9733C134.197 22.9733 133.278 22.7905 132.542 22.364Z" />
                <path d="M143.577 7.61719V9.93281H140.205V7.61719H143.577ZM140.205 11.1516H143.577V22.8516H140.205V11.1516Z" />
                <path d="M145.723 11.1518H148.297L149.033 13.1018V22.8518H145.723V11.1518ZM153.447 16.6361C153.447 15.3564 153.263 14.4424 152.957 14.0158C152.65 13.5283 152.16 13.3455 151.485 13.3455C150.811 13.3455 150.198 13.6502 149.708 14.1986C149.217 14.7471 148.972 15.4783 148.972 16.3924L147.317 15.6611C147.317 14.6252 147.807 13.5893 148.788 12.6143C149.769 11.6393 151.056 11.0908 152.65 11.0908C154.183 11.0908 155.225 11.5783 155.777 12.6143C156.329 13.6502 156.635 14.9908 156.635 16.6361V22.9127H153.325V16.6361H153.447Z" />
                <path d="M159.517 21.3281C158.781 20.2922 158.413 18.8297 158.413 16.9406C158.413 15.1125 158.781 13.65 159.517 12.5531C160.252 11.5172 161.417 10.9688 163.011 10.9688C164.421 10.9688 165.647 11.3344 166.689 12.0047C167.732 12.675 168.222 13.4062 168.222 14.1984L166.751 15.5391C166.751 14.8688 166.505 14.3812 166.015 13.8937C165.525 13.4672 164.911 13.2234 164.237 13.2234C163.501 13.2234 162.95 13.4672 162.521 13.9547C162.091 14.4422 161.908 15.4172 161.908 16.8797C161.908 18.3422 162.091 19.3172 162.521 19.8047C162.95 20.2922 163.501 20.5359 164.237 20.5359C164.911 20.5359 165.525 20.2922 166.015 19.8656C166.505 19.4391 166.751 18.8906 166.751 18.2203L168.222 19.6828C168.222 20.475 167.732 21.2063 166.689 21.8156C165.647 22.425 164.482 22.7906 163.072 22.7906C161.417 22.9125 160.252 22.3641 159.517 21.3281ZM160.436 26.5688C159.394 25.9594 158.781 24.9234 158.658 23.4H161.908C161.969 23.8266 162.091 24.2531 162.275 24.4969C162.459 24.8016 162.766 24.9844 163.072 25.1672C163.44 25.2891 163.808 25.35 164.237 25.35C164.973 25.35 165.525 25.1672 166.015 24.8625C166.444 24.4969 166.689 23.7656 166.689 22.5469V13.4672L167.609 11.1516H170.061V22.2422C170.061 23.5828 169.816 24.6797 169.264 25.4109C168.712 26.2031 168.038 26.6906 167.241 26.9953C166.444 27.3 165.402 27.4828 164.237 27.4828C162.704 27.4828 161.478 27.1781 160.436 26.5688Z" />
                <path d="M122.488 30.9563C122.243 30.7126 122.12 30.347 122.12 29.7985H122.978C122.978 30.2251 123.04 30.4688 123.224 30.5907C123.408 30.7126 123.591 30.7735 123.898 30.7735C124.143 30.7735 124.388 30.7126 124.572 30.6517C124.756 30.5907 124.818 30.4079 124.818 30.2251C124.818 30.0423 124.695 29.8595 124.511 29.7985C124.327 29.7376 124.021 29.6157 123.653 29.5548C123.101 29.4329 122.733 29.311 122.488 29.1282C122.243 28.9454 122.181 28.7017 122.181 28.397C122.181 28.0923 122.304 27.7876 122.549 27.5438C122.794 27.3001 123.285 27.1782 123.898 27.1782C124.572 27.1782 125.001 27.3001 125.247 27.5438C125.492 27.7876 125.615 28.1532 125.615 28.6407H124.756C124.756 28.2751 124.695 28.0313 124.511 27.9095C124.327 27.7876 124.143 27.7267 123.898 27.7267C123.591 27.7267 123.408 27.7876 123.224 27.8485C123.04 27.9704 122.978 28.0923 122.978 28.2142C122.978 28.397 123.101 28.5798 123.285 28.6407C123.469 28.7017 123.775 28.8235 124.143 28.8845C124.695 29.0063 125.063 29.1282 125.308 29.311C125.553 29.4329 125.615 29.6767 125.615 30.0423C125.615 30.4079 125.492 30.6517 125.247 30.8954C125.001 31.1392 124.572 31.261 123.898 31.261C123.162 31.322 122.733 31.2001 122.488 30.9563Z" />
                <path d="M128.005 30.8342C127.76 30.5295 127.576 30.042 127.576 29.4326C127.576 28.8232 127.699 28.3967 128.005 28.092C128.251 27.7873 128.741 27.6045 129.354 27.6045C129.967 27.6045 130.396 27.7873 130.703 28.092C130.948 28.3967 131.132 28.8232 131.132 29.4326C131.132 30.042 131.009 30.4686 130.703 30.8342C130.458 31.1389 129.967 31.3217 129.354 31.3217C128.68 31.3217 128.251 31.1998 128.005 30.8342ZM129.967 30.4686C130.151 30.2857 130.212 29.9811 130.212 29.4936C130.212 29.0061 130.151 28.6404 129.967 28.5186C129.783 28.3357 129.599 28.2748 129.354 28.2748C129.109 28.2748 128.864 28.3357 128.741 28.5186C128.557 28.7014 128.496 29.0061 128.496 29.4936C128.496 29.9811 128.557 30.3467 128.741 30.4686C128.925 30.6514 129.109 30.7123 129.354 30.7123C129.599 30.7732 129.783 30.6514 129.967 30.4686Z" />
                <path d="M133.217 26.5078H134.075V31.2609H133.217V26.5078Z" />
                <path d="M137.078 29.6766C137.078 30.1031 137.14 30.3469 137.262 30.5297C137.385 30.7125 137.507 30.7734 137.753 30.7734C137.998 30.7734 138.182 30.7125 138.366 30.5297C138.55 30.3469 138.611 30.1031 138.611 29.8594L139.04 30.0422C139.04 30.3469 138.917 30.6516 138.611 30.9562C138.304 31.2609 137.936 31.3828 137.446 31.3828C137.017 31.3828 136.71 31.2609 136.526 30.9562C136.343 30.6516 136.281 30.2859 136.281 29.7375V27.7266H137.14V29.6766H137.078ZM138.55 30.7125V27.7266H139.408V31.3219H138.733L138.55 30.7125Z" />
                <path d="M142.473 31.1392C142.289 31.0173 142.167 30.7735 142.167 30.4688V28.2751H141.309V27.6657H142.167V26.8735H143.025V27.6657H144.19V28.2751H143.025V30.2251C143.025 30.4079 143.086 30.5907 143.148 30.6517C143.209 30.7126 143.332 30.7735 143.516 30.7735C143.638 30.7735 143.822 30.7735 143.945 30.7126V31.261C143.7 31.322 143.454 31.322 143.209 31.322C142.964 31.322 142.719 31.261 142.473 31.1392Z" />
                <path d="M147.194 26.6904V27.3607H146.336V26.6904H147.194ZM146.336 27.7264H147.194V31.3217H146.336V27.7264Z" />
                <path d="M149.707 30.8342C149.462 30.5295 149.278 30.042 149.278 29.4326C149.278 28.8232 149.401 28.3967 149.707 28.092C149.953 27.7873 150.443 27.6045 151.056 27.6045C151.669 27.6045 152.098 27.7873 152.405 28.092C152.65 28.3967 152.834 28.8232 152.834 29.4326C152.834 30.042 152.711 30.4686 152.405 30.8342C152.16 31.1389 151.669 31.3217 151.056 31.3217C150.443 31.3217 150.014 31.1998 149.707 30.8342ZM151.669 30.4686C151.853 30.2857 151.914 29.9811 151.914 29.4936C151.914 29.0061 151.853 28.6404 151.669 28.5186C151.485 28.3357 151.301 28.2748 151.056 28.2748C150.811 28.2748 150.566 28.3357 150.443 28.5186C150.259 28.7014 150.198 29.0061 150.198 29.4936C150.198 29.9811 150.259 30.3467 150.443 30.4686C150.627 30.6514 150.811 30.7123 151.056 30.7123C151.301 30.7732 151.547 30.6514 151.669 30.4686Z" />
                <path d="M154.857 27.7264H155.532L155.716 28.3967V31.3217H154.857V27.7264ZM157.187 29.3107C157.187 28.8842 157.126 28.6404 157.003 28.4576C156.881 28.2748 156.758 28.2139 156.513 28.2139C156.267 28.2139 156.084 28.2748 155.9 28.4576C155.716 28.6404 155.654 28.8842 155.654 29.1279L155.225 28.9451C155.225 28.6404 155.348 28.3357 155.654 28.0311C155.961 27.7264 156.329 27.6045 156.758 27.6045C157.187 27.6045 157.494 27.7264 157.677 28.0311C157.861 28.3357 157.923 28.7623 157.923 29.2498V31.1998H157.064V29.3107H157.187Z" />
                <path d="M119.055 29.5547H54.8066V30.0422H119.055V29.5547Z" />
                <path d="M54.8067 28.2749C55.665 28.2749 56.3394 28.9452 56.3394 29.7983C56.3394 30.6515 55.665 31.3218 54.8067 31.3218C53.9484 31.3218 53.2741 30.6515 53.2741 29.7983C53.2128 28.9452 53.9484 28.2749 54.8067 28.2749Z" />
                <path d="M31.0205 22.8516C22.8669 22.8516 16.1846 16.2094 16.1846 8.10469V0H39.2354V13.4062H33.4727V5.72812H21.9473V8.10469C21.9473 13.1016 25.9934 17.1234 31.0205 17.1234V22.8516Z" fill="#E83C64" />
                <path d="M22.9895 39H0V25.5938H5.76271V33.2719H17.2881V30.8953C17.2881 25.8984 13.242 21.8766 8.21493 21.8766V16.1484C16.3686 16.1484 23.0508 22.7906 23.0508 30.8953V39H22.9895Z" fill="#E83C64" />
                <path d="M5.76271 14.1375H0V0H12.0772V5.72812H5.76271V14.1375Z" fill="#33B4A0" />
                <path d="M39.2352 39.0002H27.0967V33.2721H33.4724V24.8018H39.2352V39.0002Z" fill="#33B4A0" />
            </g>
            <defs>
                <clipPath id="clip0_457_1057">
                    <rect width="170" height="39" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}