// TiktokIcon.jsx
import React from 'react'

export const TiktokIcon = ({className}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      className={className}
      fill="currentColor"
    >
      <g clipPath="url(#clip0_651_1676)">
        <path
          d="M22.8491 10.7043C22.7115 10.6345 22.5776 10.558 22.4479 10.4751C22.0705 10.2303 21.7246 9.94182 21.4175 9.61592C20.6491 8.75301 20.3621 7.87759 20.2563 7.26467H20.2606C20.1723 6.75592 20.2088 6.42676 20.2143 6.42676H16.7144V19.7093C16.7144 19.8876 16.7144 20.0638 16.7067 20.238C16.7067 20.2597 16.7046 20.2797 16.7033 20.303C16.7033 20.3126 16.7033 20.3226 16.7012 20.3326C16.7012 20.3351 16.7012 20.3376 16.7012 20.3401C16.6643 20.8167 16.5086 21.277 16.2479 21.6807C15.9872 22.0844 15.6294 22.419 15.2059 22.6551C14.7646 22.9015 14.2655 23.0307 13.7578 23.0301C12.1271 23.0301 10.8055 21.7251 10.8055 20.1134C10.8055 18.5018 12.1271 17.1968 13.7578 17.1968C14.0665 17.1965 14.3733 17.2441 14.6668 17.338L14.671 13.8405C13.78 13.7276 12.8749 13.797 12.0127 14.0446C11.1504 14.2922 10.3498 14.7124 9.66136 15.2788C9.05811 15.7933 8.55095 16.407 8.16271 17.0926C8.01497 17.3426 7.45754 18.3472 7.39004 19.9776C7.34758 20.903 7.63075 21.8618 7.76576 22.258V22.2663C7.85067 22.4997 8.17969 23.2959 8.71589 23.9672C9.14827 24.5056 9.6591 24.9786 10.232 25.3709V25.3626L10.2404 25.3709C11.9348 26.5009 13.8134 26.4268 13.8134 26.4268C14.1386 26.4138 15.228 26.4268 16.4651 25.8513C17.8373 25.2134 18.6184 24.263 18.6184 24.263C19.1175 23.6951 19.5143 23.0479 19.7919 22.3493C20.1086 21.5322 20.2143 20.5522 20.2143 20.1605V13.1138C20.2568 13.1388 20.8223 13.5059 20.8223 13.5059C20.8223 13.5059 21.637 14.0184 22.9081 14.3522C23.82 14.5897 25.0486 14.6397 25.0486 14.6397V11.2297C24.6181 11.2755 23.744 11.1422 22.8491 10.7043Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_651_1676">
          <rect width="23.04" height="25.6" fill="#33B4A0" transform="translate(4.48047 3.2002)" />
        </clipPath>
      </defs>
    </svg>
  )
}