import React from 'react'

export const Chevron = ({className}) => {
    return (
        <svg className={className} width="11" height="7" viewBox="0 0 11 7" xmlns="http://www.w3.org/2000/svg">
            <g id="Group">
                <path id="Vector" d="M10.9999 0.828203C11.0029 0.667903 10.9553 0.510642 10.8638 0.378182C10.7723 0.245723 10.6413 0.144614 10.489 0.0888463C10.3367 0.0330788 10.1706 0.0254103 10.0137 0.0669026C9.85673 0.108395 9.71676 0.196996 9.61311 0.320446L5.51492 5.04493L1.41674 0.317527C1.35295 0.231162 1.27171 0.158785 1.17815 0.104964C1.08459 0.0511436 0.980737 0.0170473 0.873153 0.00482725C0.765568 -0.00739277 0.656586 0.00252904 0.553082 0.0339662C0.449578 0.0654035 0.353803 0.117674 0.271796 0.187478C0.189791 0.257282 0.123337 0.343105 0.0766277 0.43953C0.0299196 0.535955 0.00397205 0.640889 0.000421524 0.747708C-0.00312996 0.854528 0.0157928 0.960914 0.0559969 1.06015C0.0962009 1.15939 0.156813 1.24933 0.234006 1.32429L4.91469 6.7287C4.98825 6.81369 5.0796 6.88193 5.18246 6.92873C5.28532 6.97552 5.39723 6.99976 5.51049 6.99976C5.62375 6.99976 5.73566 6.97552 5.83852 6.92873C5.94138 6.88193 6.03273 6.81369 6.10629 6.7287L10.8018 1.32429C10.9253 1.18734 10.9955 1.01151 10.9999 0.828203Z" />
            </g>
        </svg>
    )
}