import React from 'react'

import { close } from '../assets'

export const MessagePopup = ({ popup, icon, heading, message }) => {
    return (
        <dialog ref={popup} className='fixed top-1/2 p-5 sm:p-6 xl:p-8 rounded-2xl fade-in-top backdrop:opacity-80 backdrop:bg-black my-0 w-[335px]'>
            <img src={close} alt='Close icon' className='absolute right-5 xl:right-12 top-6 xl:top-8 cursor-pointer' onClick={() => popup.current.close()} />
            <img src={icon} alt='Check icon' className='mx-auto mt-6 size-14' />
            <div className="mt-4 space-y-1 text-center">
                <h3>{heading}</h3>
                <p className='text-[--black] opacity-80'>{message}</p>
            </div>
        </dialog>
    )
}