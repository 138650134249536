import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { searchContent } from '../utils/searchContent'; // Import search function

import { broucher, close, navMenu, navMinus, navPlus } from '../assets'
import { Chevron, Logo, Search, NavSearchResult } from './index'
import { products } from '../Data/Products'

export const Navbar = () => {
    const [showNav, setShowNav] = useState(false);
    const [navOpts, setNavOpts] = useState([false, false]);
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [padding, setPadding] = useState(120);
    const navigate = useNavigate();
    const inputRef = useRef(null);
    const blurTimeoutRef = useRef(null);
    const [query, setQuery] = useState(''); // State for search query
    const [results, setResults] = useState([]); // State for search results

    // Update products by adding isOpen for toggle in small screens and top property for large screens
    const [navProds, setNavProds] = useState(() => {
        let top;
        let newProducts = products.map((product, i) => {
            if (product.hasOwnProperty('items')) {
                switch (i) {
                    case 0:
                        top = 0;
                        break;
                    case 1:
                        top = 51;
                        break;
                    default:
                        top += 43;
                        break;
                }
                return { ...product, isOpen: false, top: `${top}px` };
            }
            return { ...product, isOpen: false };
        });
        return newProducts;
    })


    useEffect(() => {
        let width = document.body.offsetWidth
        if (width > 1440) setPadding(padding + ((width - 1440) / 2))
        else if (width >= 768) setPadding(60)
        else setPadding(20)

        window.scrollTo(0, 0);
    }, []);

    const closeNav = () => {
        setShowNav(false)
        toggleNavOpts(-1)
    }

    const toggleNavOpts = (index) => {
        if (document.body.offsetWidth < 1440) {
            setNavOpts(prevState => prevState.map((value, i) => i === index ? !value : false));
            toggleSubLinks(-1)
        }
    }

    const toggleSubLinks = (index) => {
        if (document.body.offsetWidth < 1440) {
            const updated = navProds?.map((prod, i) => i === index ?
                { ...prod, isOpen: !prod.isOpen }
                : { ...prod, isOpen: false }
            )
            setNavProds(updated)
        }
    }

    const handleRedirection = (e, link) => {
        e?.preventDefault()
        setQuery("")
        closeNav()
        navigate(link)
    }

    const handleSearchChange = (e) => {
        const searchQuery = e.target.value;
        setQuery(searchQuery);
        const searchResults = searchContent(searchQuery);
        setResults(searchResults);
    }

    const handleBlur = () => {
        blurTimeoutRef.current && clearTimeout(blurTimeoutRef.current);
        blurTimeoutRef.current = setTimeout(() => setIsInputFocused(false), 200);
    }

    const handleFocus = () => {
        blurTimeoutRef.current && clearTimeout(blurTimeoutRef.current);
        setIsInputFocused(true);
    }

    const handleCloseClick = (event) => {
        event.preventDefault();
        setQuery('');
        inputRef.current.focus();
    }


    return (
        <nav className='sm:hidden xl:block'>
            <div className='bg-[--black] xl:bg-[#F4EEE5]'>
                <div className='flex items-center justify-between xl:justify-center px-4 xl:px-[--padding-x-lg] py-3 center'>
                    <Link to='/' className='text-white xl:text-black'><Logo className='fill-current' /></Link>
                    <img src={navMenu} alt='Hamburger icon' className='xl:hidden' onClick={() => setShowNav(true)} />

                    {/* Desktop screen - Search bar */}
                    <div className={`hidden xl:flex justify-end absolute group`} style={{ right: `${padding}px` }}>
                        <input
                            className='rounded-[10px] py-2 transition-all w-0 group-hover:w-[378px] group-hover:pl-5 group-hover:pr-14 focus-visible:pr-14 focus-visible:pl-5 focus-visible:w-[378px]'
                            name='searchProducts'
                            placeholder='i.e. Standee'
                            value={query}
                            ref={inputRef}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            onChange={handleSearchChange} // Update search query on change
                        />
                        <div className='absolute right-4 top-1/2 -translate-y-1/2 flex items-center gap-x-1'>
                            {(query && isInputFocused) &&
                                <img src={close} alt="Remove query" title='Remove query'
                                    className='cursor-pointer opacity-50'
                                    onClick={handleCloseClick}
                                />
                            }
                            <Search className="size-6 fill-current" />
                        </div>
                    </div>
                </div>


                {/* Mobile screen - Search bar */}
                <div className='xl:hidden pb-2 px-5 relative'>
                    <input
                        className='rounded-[10px] py-2 pl-5 pr-14 w-full focus-visible:outline-black'
                        name='searchProducts'
                        placeholder='Search Products'
                        value={query}
                        ref={inputRef}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        onChange={handleSearchChange} // Update search query on change
                    />
                    <div className='absolute top-5 right-4 -translate-x-1/2 -translate-y-1/2 flex items-center gap-x-1'>
                        {(query && isInputFocused) &&
                            <img src={close} alt="Remove query" title='Remove query' className='cursor-pointer opacity-50' onClick={handleCloseClick} />
                        }
                        <Search className="size-6 fill-current" />
                    </div>
                </div>


                {/* Display search results */}
                {isInputFocused && <NavSearchResult query={query} results={results} handleRedirection={handleRedirection} />}

            </div>



            {/* Links */}
            <div className={`${showNav ? 'fixed' : 'hidden'} xl:hidden top-0 left-0 w-full h-screen bg-[#000000cc] z-20`} onClick={closeNav} />
            <div className={`bg-white flex gap-x-1 max-xl:fixed top-0 right-0 max-xl:h-screen max-xl:px-5 max-xl:pt-5 max-xl:pb-16 max-xl:z-30 xl:bg-[--black] xl:justify-center items-start xl:items-center xl:text-white max-xl:overflow-y-auto ${showNav ? 'max-xl:flex-col' : 'max-xl:hidden'} slide-in-right max-xl:w-[311px]`}>

                <img src={close} alt='Close icon' className='my-4 self-end xl:hidden' onClick={closeNav} />

                <Link to='/' className='max-xl:w-full p-4 xl:px-5 xl:py-2.5 hover:bg-[--green] max-xl:border-b'>Home</Link>


                <Link to="/about-us" className='max-xl:w-full p-4 xl:px-5 xl:py-2.5 hover:bg-[--green] max-xl:border-b'>About Us</Link>



                {/* Products */}
                <div className='relative dropdown max-xl:border-b max-xl:w-full'>


                    {/* Large Screen */}
                    <Link to="/products" className='hidden xl:flex items-center gap-x-2 px-5 py-2.5 hover:bg-[--green] cursor-pointer' onClick={() => toggleNavOpts(0)}>
                        <span>Products</span>
                        <div> <Chevron className="fill-current" /> </div>
                    </Link>



                    {/* Small Screen */}
                    <span className='flex xl:hidden items-center justify-between p-4' onClick={() => toggleNavOpts(0)}>
                        <Link to="/products">Products</Link>
                        {navOpts[0] ? <img src={navMinus} alt='Close Icon' /> : <img src={navPlus} alt='Open icon' />}
                    </span>



                    <ul className={`xl:absolute top-[42px] left-0 bg-white text-black z-10 pt-2 pb-4 xl:pb-2 dropdown-menu dropdown-shadow ${navOpts[0] ? 'block' : 'hidden'} scale-up-ver-top xl:z-20`}>

                        {navProds?.map((product, i) => (
                            <li key={product.category} className='drop-sub-hover'>
                                <div className='flex' onClick={() => toggleSubLinks(i)}>
                                    <span className='h-full w-2 max-xl:hidden' />
                                    {product.items ?

                                        // Category with multiple items {Ink, stands, kiosk table, etc}
                                        <span className={`pl-8 pr-4 xl:px-5 py-2 xl:py-2.5 xl:border-b xl:hover:bg-[--green-hover] w-full xl:w-[--nav-link-w] inline-flex justify-between items-center ${product.isOpen && 'max-xl:bg-[#32b3a01a]'}`}>
                                            <span>{product.category}</span>
                                            <div className={`max-xl:transition-all max-xl:size-3 xl:-rotate-90 ${product.isOpen && 'max-xl:-rotate-180'}`}>
                                                <Chevron className="fill-[--black-bg] size-3" />
                                            </div>
                                        </span>
                                        :

                                        // Category with single item {wipes and eyelets}
                                        <Link to={`/products/${product.item.category}/${product.item.id}`}
                                            onClick={(e) => handleRedirection(e, `/products/${product.item.category}/${product.item.id}`)}
                                            className='pl-8 pr-4 xl:px-5 py-2 xl:py-2.5 xl:border-b xl:hover:bg-[--green-hover] w-full xl:w-[--nav-link-w] inline-block'
                                        >{product.item.name}</Link>
                                    }
                                    <span className='h-full w-2 max-xl:hidden' />
                                </div>

                                {product.items &&
                                    <ul className={`xl:absolute -right-[266px] bg-white text-black z-10 py-2  xl:px-2 xl:divide-y dropdown-sub dropdown-sub-shadow ${product.isOpen ? 'block max-xl:bg-[#32b3a00d]' : 'hidden'} scale-up-ver-top`}
                                        style={{ top: product.top }}
                                    >
                                        {product.items?.map(item => (
                                            <li key={item.id}>
                                                <Link to={`/products/${item.category}/${item.id}`}
                                                    onClick={(e) => handleRedirection(e, `/products/${item.category}/${item.id}`)}
                                                    className='pr-2 pl-16 xl:px-5 py-2 xl:py-2.5 hover:bg-[--green-hover] w-full xl:w-[--nav-link-w] inline-block'
                                                >{item.name}</Link>
                                            </li>
                                        ))}
                                    </ul>
                                }

                            </li>
                        ))}
                    </ul>
                </div>



                {/* Resources */}
                <div className='relative dropdown max-xl:border-b max-xl:w-full'>
                    <span className='flex items-center max-xl:justify-between xl:gap-x-2 p-4 xl:px-5 xl:py-2.5 xl:hover:bg-[--green] xl:cursor-pointer' onClick={() => toggleNavOpts(1)}>
                        <span>Resources</span>
                        <div className='max-xl:hidden'> <Chevron className="fill-current" /> </div>
                        {navOpts[1] ? <img src={navMinus} alt='Close Icon' className='xl:hidden' /> :
                            <img src={navPlus} alt='Open icon' className='xl:hidden' />}
                    </span>


                    <ul className={`xl:absolute top-[42px] left-0 bg-white text-black z-10 pt-2 pb-4 xl:pb-2 dropdown-menu dropdown-shadow ${navOpts[1] ? 'block' : 'hidden'} scale-up-ver-top`}>

                        <li className='xl:px-2'>
                            <Link to="/gallery" className='pl-8 pr-4 xl:px-5 py-2 xl:py-2.5 xl:border-b xl:hover:bg-[--green-hover] w-full xl:w-[--nav-link-w] inline-block'>Gallery</Link>
                        </li>

                        <li className='xl:px-2'>
                            <a href={broucher} rel='noreferrer' target='_blank' className='pl-8 pr-4 xl:px-5 py-2 xl:py-2.5 xl:border-b xl:hover:bg-[--green-hover] w-full xl:w-[--nav-link-w] inline-block'>Company Brochure</a>
                        </li>

                        <li className='xl:px-2'>
                            <Link to='/privacy-policy' className='pl-8 pr-4 xl:px-5 py-2 xl:py-2.5 xl:hover:bg-[--green-hover] w-full xl:w-[--nav-link-w] inline-block'>Privacy and Cookies Policy</Link>
                        </li>
                    </ul>
                </div>


                <Link to='/contact-us' className='max-xl:w-full p-4 xl:px-5 xl:py-2.5 hover:bg-[--green] max-xl:border-b'>Contact Us</Link>
            </div >


        </nav>
    )
}