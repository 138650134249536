import React, { useEffect, useRef, useState } from "react";

import { Header, NavScroll, Navbar, Footer, FollowInstagram, Location, Email, Phone, MessagePopup } from "../Components";
import { heroContact, check, cross } from "../assets";

import socialLinks from "../Data/SocialLinks";

export const ContactUs = () => {
  const [padding, setPadding] = useState(120);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(null);
  const [message, setMessage] = useState("");
  const [recaptchaError, setRecaptchaError] = useState("");
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const popup = useRef(null);


  useEffect(() => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute("6LcWRQkqAAAAAH3LhnRGYABbqmLvtxKWcPd5UwZG", {
          action: "submit",
        })
        .then((token) => {
          setRecaptchaToken(token);
        });
    });

    let width = document.body.offsetWidth;
    if (width > 1440) setPadding(padding + (width - 1440) / 2);
    else if (width >= 768) setPadding(60);
    else setPadding(20);

    window.scrollTo(0, 0);
  }, []);


  useEffect(() => {
    if (isSubmitted != null) {
      popup.current.showModal();
    }
  }, [isSubmitted]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!recaptchaToken) {
      setRecaptchaError("Please complete the reCAPTCHA.");
      return;
    }

    try {
      const payload = { ...formData, "g-recaptcha-response": recaptchaToken };
      console.log("Submitting form with payload:", payload);

      const response = await fetch("https://formspree.io/f/mvgpovbj", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      if (response.ok) {
        setIsSubmitted(true);
        setMessage("Your message has been sent successfully!");
        setFormData({ name: "", email: "", message: "" });
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setIsSubmitted(false);
      setMessage("Something went wrong. Please try again.");
    }
  };

  return (
    <main>
      {/* Header */}
      <Header />

      {/* Navbar */}
      <Navbar />

      {/* Navbar on scroll */}
      <NavScroll />




      {/* Hero Section */}
      <section
        style={{
          backgroundImage: `url(${heroContact})`,
          paddingLeft: `${padding}px`,
        }}
        className="h-[300px] sm:h-[400px] bg-no-repeat bg-[#000000b3] bg-blend-multiply flex items-center text-white bg-center bg-cover sm:bg-contain sm:bg-right"
      >
        <h1>Contact Us</h1>
      </section>






      {/* Contact */}
      <section className="bg-[#F4EEE5]">
        <div className="px-5 sm:px-[--padding-x-sm] xl:px-[--padding-x-lg] py-9 sm:py-14 xl:py-[--padding-y-lg] center">
          <div className="grid xl:grid-cols-2 sm:gap-y-6">
            <div className="px-5 sm:px-[--padding-x-sm] py-9 sm:py-14 xl:p-20 space-y-4 sm:space-y-6 xl:space-y-[50px] max-sm:border-b xl:border-r border-[#E8E8E8] bg-white">
              <h3>Send Us A Message</h3>

              <form
                className="space-y-8 sm:space-y-6 xl:space-y-[50px]"
                onSubmit={handleSubmit}
              >
                <div className="space-y-2 sm:space-y-6">
                  <input
                    className="px-3 py-2 w-full input-border"
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />

                  <input
                    className="px-3 py-2 w-full input-border"
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />

                  <textarea
                    className="px-3 py-2 w-full input-border"
                    id="message"
                    name="message"
                    cols="30"
                    rows="5"
                    placeholder="How Can We Help?"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  ></textarea>
                </div>
                <button type="submit" className="button-black">
                  Submit
                </button>
              </form>
              {recaptchaError && <p className="text-red-500">{recaptchaError}</p>}
            </div>



            <div className="px-[30px] sm:px-[--padding-x-sm] py-9 sm:py-14 xl:p-20 space-y-6 xl:space-y-8 bg-white">
              <h3>Get In Touch</h3>

              <div className="space-y-3 sm:space-y-6 xl:space-y-8">
                <div className="flex gap-x-4 items-start">
                  <Location fill="#333333" />
                  <div className="space-y-3 sm:space-y-6">
                    <p className="opacity-80 font-semibold">Address</p>

                    <div className="space-y-1">
                      <p className="font-semibold">Karachi</p>
                      <p>Nazimabad - Pakistan Chowk</p>
                    </div>

                    <div className="space-y-1">
                      <p className="font-semibold">Lahore</p>
                      <p>Sabzazar</p>
                    </div>

                    <div className="space-y-1">
                      <p className="font-semibold">Rawalpindi</p>
                      <p>Commercial Market</p>
                    </div>
                  </div>
                </div>

                <div className="flex gap-x-4 items-start">
                  <Phone fill="#333333" />
                  <div className="space-y-2">
                    <p className="opacity-80 font-semibold">Let's Talk</p>
                    <p>+92-310-280-6759</p>
                  </div>
                </div>

                <div className="flex gap-x-4 items-start">
                  <Email fill="#333333" />
                  <div className="space-y-2">
                    <p className="opacity-80 font-semibold">Email</p>
                    <p>contact@skprintingsolution.com</p>
                  </div>
                </div>

                <div className="space-y-2.5 hidden sm:block">
                  <p className="text-[--black] opacity-80 font-semibold space-y-2">
                    Social Links
                  </p>
                  <div className="flex">
                    {socialLinks.map((link) => {
                      return (
                        <a
                          key={link.site}
                          href={link.link}
                          target="_blank"
                          title={link.site}
                          className="text-black hover:text-[--green] transition-colors duration-200"
                        >
                          <link.icon className="h-8 w-8 fill-current" />
                        </a>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>






      {/* Map */}
      <iframe
        title="Map for SK Printing solution"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3618.736194645381!2d67.0304497740136!3d24.906978543397116!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33fa963d6aff9%3A0x7aa7943041ead0a0!2sSk%20Printing%20Solution!5e0!3m2!1sen!2s!4v1714523884604!5m2!1sen!2s"
        width="100%"
        height="450"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />





      {/* Follow Instagram */}
      <FollowInstagram backGround="white" />

      {/* Footer */}
      <Footer />





      <MessagePopup
        popup={popup}
        icon={isSubmitted ? check : cross}
        heading={isSubmitted ? "Success" : "Error"}
        message={message}
      />


    </main>
  );
};
