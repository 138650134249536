import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { closeWhite, zoomIn, share, popupDownload, popupFacebook, popupInstagram } from '../assets';
import { Chevron } from './Icons/Chevron';

const localBaseURL = process.env.REACT_APP_LOCAL_BASE_URL;
const productionBaseURL = process.env.REACT_APP_PRODUCTION_BASE_URL;
const isProduction = process.env.NODE_ENV === 'production';
const baseURL = isProduction ? productionBaseURL : localBaseURL;

console.log('Local Base URL:', localBaseURL);
console.log('Production Base URL:', productionBaseURL);
console.log('Is Production:', isProduction);

export const SliderPopup = ({ popup, images, galleryIndex = 0 }) => {
    const [sharePopup, setSharePopup] = useState(false);
    const [imageNo, setImageNo] = useState(galleryIndex);
    const swiperRef = useRef(null);
    const [isZoom, setIsZoom] = useState(false);


    useEffect(() => {
        popup.current.addEventListener("close", () => {
            setImageNo(galleryIndex);
            swiperRef.current.swiper.slideTo(galleryIndex);
        });

        swiperRef.current.swiper.slideTo(galleryIndex);
    }, [galleryIndex]);


    const handleSlideChange = (e) => popup.current.open && setImageNo(e.activeIndex);

    const getFullImageURL = (image) => {
        // Check if the image URL is already an absolute URL
        if (image.startsWith('http://') || image.startsWith('https://')) {
            return image;
        }
        return `${baseURL}${image}`;
    };

    const shareOnFacebook = (imageURL) => {
        const facebookShareURL = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(imageURL)}`;
        window.open(facebookShareURL, '_blank');
    };


    return (
        <dialog ref={popup} className='fixed top-0 max-w-full max-h-full h-full text-white m-0 z-20 backdrop:opacity-80 backdrop:bg-black bg-transparent'>
            <div className='bg-[--black-2] flex justify-between p-4'>
                <p>{imageNo + 1}/{images?.length}</p>
                <div className='flex gap-x-[17px] cursor-pointer'>
                    <img src={zoomIn} alt='Zoom icon' className='max-sm:hidden' onClick={() => setIsZoom(!isZoom)} />
                    <img src={share} alt='Share icon' onClick={() => setSharePopup(true)} />
                    <img src={closeWhite} alt='Close icon' onClick={() => popup.current.close()} />
                </div>

                {sharePopup && (
                    <div className="absolute w-full h-screen top-0 left-0 bg-[#0000004d] z-10" onClick={() => setSharePopup(false)}>
                        <img src={share} alt='Share icon' onClick={() => setSharePopup(false)} className="absolute top-4 right-14 cursor-pointer" />
                        <ul className="absolute top-[--padding-x-sm] right-1/2 max-sm:translate-x-1/2 sm:right-7 p-4 rounded-[15px] divide-y bg-white text-black sm:!w-[282px]" style={{ width: 'calc(100% - 80px)' }}>
                            <li className="pl-5 pr-2.5 py-2.5 flex items-center gap-x-4 hover:bg-[--green-hover] transition-colors cursor-pointer"
                                onClick={() => shareOnFacebook(getFullImageURL(images[imageNo].img))}>
                                <img src={popupFacebook} alt='Facebook' />
                                <span>Facebook</span>
                            </li>

                            {/* <li className="pl-5 pr-2.5 py-2.5 flex items-center gap-x-4 hover:bg-[--green-hover] transition-colors cursor-pointer"
                                onClick={() => shareOnInstagram(getFullImageURL(images[imageNo].img))}>
                                <img src={popupInstagram} alt='Instagram' />
                                <span>Instagram</span>
                            </li> */}

                            <li className='pt-9'>
                                <a href={getFullImageURL(images[imageNo].img)} className="button-black flex justify-center items-center gap-x-3" download>
                                    <span>Download</span>
                                    <img src={popupDownload} alt='Download' />
                                </a>
                            </li>
                        </ul>
                    </div>
                )}
            </div>

            <div className='relative w-full'>
                <Swiper
                    ref={swiperRef}
                    navigation={{
                        nextEl: '.next-popup',
                        prevEl: '.prev-popup'
                    }}
                    modules={[Navigation]}
                    onSlideChange={handleSlideChange}
                    className="mySwiper select-none"
                    style={{ height: "calc(100vh - 112px)" }}
                >
                    {images?.map((image, i) => (
                        <SwiperSlide key={i} className="!flex items-center py-16">
                            <img src={image.img} alt={image.name} className={`sm:h-full mx-auto ${isZoom && 'sm:scale-150'}`} />
                        </SwiperSlide>
                    ))}
                </Swiper>
                {/* Next */}
                <div className="next-popup h-full px-3 sm:px-[--padding-x-sm] content-center cursor-pointer z-[1]">
                    <Chevron className="fill-current size-4 -rotate-90" />
                </div>
                {/* Prev */}
                <div className="prev-popup h-full px-3 sm:px-[--padding-x-sm] content-center cursor-pointer z-[1]">
                    <Chevron className="fill-current size-4 rotate-90" />
                </div>
            </div>

            <div className='bg-[--black-2] p-4 absolute bottom-0 w-full text-center'>{images && images[imageNo]?.name}</div>
        </dialog>
    );
};