import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import './index.css';
import { ContactUs, Gallery, Homepage, Products, PrivacyPolicy, SingleProduct, AboutUs, ProductsByCategory, SearchProduct } from './Pages';
import { Cookies, ErrorBoundary, GoToTop, Whatsapp } from './Components';

function BuggyComponent() {
  throw new Error('This is a test error');
}

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<ErrorBoundary> <Homepage /> </ErrorBoundary>} />
          <Route path="/contact-us" element={<ErrorBoundary> <ContactUs /> </ErrorBoundary>} />
          <Route path="/gallery" element={<ErrorBoundary> <Gallery /> </ErrorBoundary>} />
          <Route path="/products" element={<ErrorBoundary> <Products /> </ErrorBoundary>} />
          <Route path="/products/:category" element={<ErrorBoundary> <ProductsByCategory /> </ErrorBoundary>} />
          <Route path="/products/:category/:productId" element={<ErrorBoundary> <SingleProduct /> </ErrorBoundary>} />
          <Route path='/products/search' element={<ErrorBoundary><SearchProduct /></ErrorBoundary>} />
          <Route path="/privacy-policy" element={<ErrorBoundary> <PrivacyPolicy /> </ErrorBoundary>} />
          <Route path="/about-us" element={<ErrorBoundary> <AboutUs /> </ErrorBoundary>} />
          <Route path="/buggy" element={<ErrorBoundary> <BuggyComponent /> </ErrorBoundary>} />
        </Routes>
      </Router>

      <Cookies />
      <GoToTop />
      <Whatsapp />
    </>
  );
}

export default App;