// Pages/gallery.jsx
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { Header, NavScroll, Navbar, Footer, FollowInstagram, SliderPopup } from "../Components/index";

import { heroGallery, gallery11, gallery12, gallery13, gallery14, gallery21, gallery22, gallery23, gallery24, gallery25, gallery26, gallery27, gallery28, gallery29, gallery31, gallery32, gallery33, gallery34, gallery35, gallery36, gallery37, gallery38, gallery41, gallery42, gallery43, gallery44, gallery51, gallery52, gallery53, gallery54, gallery55, gallery56, gallery61, gallery62, gallery63, gallery71, gallery72, gallery73, gallery81, gallery82, gallery91, gallery92, gallery101, gallery102, gallery103, gallery104, gallery105, gallery106, gallery111, gallery121, gallery122, gallery131, gallery141, gallery151, gallery161, gallery162, gallery171 } from "../assets";


let images = [
    { name: "Customer 1", img: gallery11 },
    { name: "Customer 2", img: gallery12 },
    { name: "Customer 3", img: gallery13 },
    { name: "Customer 4", img: gallery14 },
    { name: "Ali Dairy Shop 1", img: gallery21 },
    { name: "Ali Dairy Shop 2", img: gallery22 },
    { name: "Ali Dairy Shop 3", img: gallery23 },
    { name: "Ali Dairy Shop 4", img: gallery24 },
    { name: "Ali Dairy Shop 5", img: gallery25 },
    { name: "Ali Dairy Shop 6", img: gallery26 },
    { name: "Ali Dairy Shop 7", img: gallery27 },
    { name: "Ali Dairy Shop 8", img: gallery28 },
    { name: "Ali Dairy Shop 9", img: gallery29 },
    { name: "Hani Chemical 1", img: gallery31 },
    { name: "Hani Chemical 2", img: gallery32 },
    { name: "Hani Chemical 3", img: gallery33 },
    { name: "Hani Chemical 4", img: gallery34 },
    { name: "Hani Chemical 5", img: gallery35 },
    { name: "Hani Chemical 6", img: gallery36 },
    { name: "Hani Chemical 7", img: gallery37 },
    { name: "Hani Chemical 8", img: gallery38 },
    { name: "Hamza Garments 1", img: gallery41 },
    { name: "Hamza Garments 2", img: gallery42 },
    { name: "Hamza Garments 3", img: gallery43 },
    { name: "Hamza Garments 4", img: gallery44 },
    { name: "Karachi Tutor Academy 1", img: gallery51 },
    { name: "Karachi Tutor Academy 2", img: gallery52 },
    { name: "Karachi Tutor Academy 3", img: gallery53 },
    { name: "Karachi Tutor Academy 4", img: gallery54 },
    { name: "Karachi Tutor Academy 5", img: gallery55 },
    { name: "Karachi Tutor Academy 6", img: gallery56 },
    { name: "Roomi Real Estate 1", img: gallery61 },
    { name: "Roomi Real Estate 2", img: gallery62 },
    { name: "Roomi Real Estate 3", img: gallery63 },
    { name: "Crystal Clear 1", img: gallery71 },
    { name: "Crystal Clear 2", img: gallery72 },
    { name: "Crystal Clear 3", img: gallery73 },
    { name: "IR Kitchen 1", img: gallery81 },
    { name: "IR Kitchen 2", img: gallery82 },
    { name: "Nisar Wood Work 1", img: gallery91 },
    { name: "Nisar Wood Work 2", img: gallery92 },
    { name: "Fine Milk Shop 1", img: gallery101 },
    { name: "Fine Milk Shop 2", img: gallery102 },
    { name: "Fine Milk Shop 3", img: gallery103 },
    { name: "Fine Milk Shop 4", img: gallery104 },
    { name: "Fine Milk Shop 5", img: gallery105 },
    { name: "Fine Milk Shop 6", img: gallery106 },
    { name: "A.G Moosa and Co.", img: gallery111 },
    { name: "Madarsa tul Madinah 1", img: gallery121 },
    { name: "Madarsa tul Madinah 2", img: gallery122 },
    { name: "T&T Water Plant", img: gallery131 },
    { name: "Super Donuts by Abdul Waris", img: gallery141 },
    { name: "Zubair Medical", img: gallery151 },
    { name: "Faizan Academy 1", img: gallery161 },
    { name: "Faizan Academy 2", img: gallery162 },
    { name: "Luminar Education Academy", img: gallery171 },
];


export const Gallery = () => {
    const [padding, setPadding] = useState(120);
    const [galleryIndex, setGalleryIndex] = useState(0);
    const popup = useRef();


    useEffect(() => {
        let width = document.body.offsetWidth;
        if (width > 1440) setPadding(padding + ((width - 1440) / 2));
        else if (width >= 768) setPadding(60);
        else setPadding(20);

        window.scrollTo(0, 0);
    }, []);


    const showPopup = (i) => {
        setGalleryIndex(i);
        popup.current.showModal();
    };


    return (
        <main>
            {/* Header */}
            <Header />

            {/* Navbar */}
            <Navbar />

            {/* Navbar on scroll */}
            <NavScroll />




            {/* Hero Section */}
            <section className={`h-[300px] sm:h-[400px] flex items-center text-white bg-no-repeat bg-[#000000b3] bg-blend-multiply bg-[80%] sm:bg-center bg-cover`}
                style={{ backgroundImage: `url(${heroGallery})`, paddingLeft: `${padding}px` }}>

                {/* Large Screen */}
                <div>
                    <h1>Gallery</h1>
                    <p className="sm:text-white max-sm:hidden opacity-70 mt-4 w-[480px] xl:w-[790px]">
                        Welcome to our portfolio! We specialize in high-quality,
                        cost-effective printing solutions for businesses of all sizes.
                        Explore our diverse range of projects, from brochures and packaging
                        to banners and apparel, and see how we can bring your vision to
                        life. Whether you need short-run orders or large-scale production,
                        we offer the expertise and resources to ensure your printed
                        materials make a lasting impression.
                    </p>
                    <Link to="/contact-us" className="block bg-white max-sm:hidden sm:w-fit text-[--black] px-[34px] py-2.5 mt-6 xl:mt-10 hover:text-white hover:bg-[--green]">Contact Us</Link>
                </div>
            </section>



            {/* Hero - Small Screen */}
            <div className="px-5 py-9 space-y-[46px] block sm:hidden">
                <p className="text-[#484E4E] text-center">
                    Welcome to our portfolio! We specialize in high-quality,
                    cost-effective printing solutions for businesses of all sizes. Explore
                    our diverse range of projects, from brochures and packaging to banners
                    and apparel, and see how we can bring your vision to life. Whether you
                    need short-run orders or large-scale production, we offer the
                    expertise and resources to ensure your printed materials make a
                    lasting impression.
                </p>
                <Link to="/contact-us" className="button-black block text-center">Contact Us</Link>
            </div>



            {/* Gallery */}
            <section className="grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-4 gap-2.5 sm:gap-6 xl:gap-8
            px-6 xl:px-8 sm:py-14 xl:py-[--padding-y-lg]">
                {images.map((image, i) => (
                    <img key={i} src={image.img} alt={image.name} className="size-full hover:brightness-[0.6] cursor-pointer"
                        onClick={() => showPopup(i)} />
                ))}
            </section>



            {/* Follow Instagram */}
            <FollowInstagram backGround="#F4EEE5" />

            {/* Footer */}
            <Footer />

            {/* Gallery popup */}
            <SliderPopup
                popup={popup}
                images={images}
                galleryIndex={galleryIndex}
            />
        </main>
    );
};