import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { searchContent } from '../utils/searchContent'; // Import search function

import { broucher, close, closeWhite, navMenu, navMinus, navPlus } from '../assets'
import { Chevron, Logo, NavSearchResult, Search } from './index'
import { products } from '../Data/Products'


export const NavScroll = () => {
    const [scrollNav, setScrollNav] = useState(false);
    const [toggleSearch, setToggleSearch] = useState(false);
    const [showNav, setShowNav] = useState(false);
    const [navOpts, setNavOpts] = useState([false, false]);
    const [isInputFocused, setIsInputFocused] = useState(false);
    const navigate = useNavigate();
    const inputRef = useRef(null);
    const blurTimeoutRef = useRef(null);
    const [query, setQuery] = useState(''); // State for search query
    const [results, setResults] = useState([]); // State for search results

    // Update products by adding isOpen for toggle in small screens and top property for large screens
    const [navProds, setNavProds] = useState(() => {
        let top;
        let newProducts = products.map((product, i) => {
            if (product.hasOwnProperty('items')) {
                switch (i) {
                    case 0:
                        top = 10;
                        break;
                    case 1:
                        top = 61;
                        break;
                    default:
                        top += 43;
                        break;
                }
                return { ...product, isOpen: false, top: `${top}px` };
            }
            return { ...product, isOpen: false };
        });
        return newProducts;
    })


    useEffect(() => {
        if (document.body.offsetWidth < 1440 && document.body.offsetWidth >= 768) {
            setScrollNav(true)
        } else {
            window.addEventListener('scroll', () => {
                if (window.pageYOffset > 200) {
                    setScrollNav(true)
                } else {
                    setScrollNav(false)
                    closeNav()
                }
            })
        }
    }, [])


    const closeNav = () => {
        setShowNav(false)
        toggleNavOpts(-1)
        setQuery("")
    }

    const toggleNavOpts = (index) => {
        if (document.body.offsetWidth < 1440) {
            setNavOpts(prevState => prevState.map((value, i) => i === index ? !value : false));
            toggleSubLinks(-1)
        }
    }

    const toggleSubLinks = (index) => {
        if (document.body.offsetWidth < 1440) {
            const updated = navProds?.map((prod, i) => i === index ?
                { ...prod, isOpen: !prod.isOpen }
                : { ...prod, isOpen: false }
            )
            setNavProds(updated)
        }
    }

    const handleRedirection = (e, link) => {
        e.preventDefault()
        closeNav()
        navigate(link)
    }

    const handleSearchChange = (e) => {
        const query = e.target.value;
        setQuery(query);
        const searchResults = searchContent(query);
        setResults(searchResults);
    }

    const closeNavSearchMblScreen = () => {
        setToggleSearch(false)
        setQuery("")
    }

    const handleBlur = () => {
        blurTimeoutRef.current && clearTimeout(blurTimeoutRef.current);
        blurTimeoutRef.current = setTimeout(() => setIsInputFocused(false), 200);
    }

    const handleFocus = () => {
        blurTimeoutRef.current && clearTimeout(blurTimeoutRef.current);
        setIsInputFocused(true);
    }

    const handleCloseClick = (event) => {
        event.preventDefault();
        setQuery('');
        inputRef.current.focus();
    }


    return (
        <>
            {scrollNav &&
                <div className='sm:sticky xl:static top-0 sm:z-10'>
                    <div className={`${showNav ? 'fixed' : 'hidden'} xl:hidden top-0 left-0 w-full h-screen bg-[#000000cc] z-20`} onClick={closeNav} />

                    <nav className='bg-[#222222] fixed sm:static xl:fixed top-0 w-full z-20'>
                        <div className='center py-5 sm:py-3 xl:py-2.5 px-5 sm:px-6 xl:px-[--padding-x-lg] flex justify-between items-center'>
                            <Link to='/'> <Logo className='fill-white' /> </Link>

                            <div className='flex items-center gap-x-2 xl:gap-x-5'>


                                {/* Links */}
                                <div className={`flex gap-x-1 max-xl:fixed top-0 right-0 max-xl:h-screen max-xl:px-5 max-xl:pt-5 max-xl:pb-16 max-xl:z-40 xl:justify-center items-start xl:items-center xl:text-white max-xl:bg-white max-xl:overflow-y-auto ${showNav ? 'max-xl:flex-col' : 'max-xl:hidden'} slide-in-right max-sm:w-[311px] max-xl:w-[540px]`}>

                                    <img src={close} alt='Nav Hamburger' className='my-4 self-end xl:hidden' onClick={closeNav} />

                                    <Link to='/' className='max-xl:w-full p-4 xl:px-5 xl:py-2.5 hover:bg-[--green] max-xl:border-b'>Home</Link>

                                    <Link to="/about-us" className='max-xl:w-full p-4 xl:px-5 xl:py-2.5 hover:bg-[--green] max-xl:border-b'>About Us</Link>



                                    {/* Products */}
                                    <div className='relative dropdown max-xl:border-b max-xl:w-full'>


                                        {/* Large Screen */}
                                        <Link to="/products" className='hidden xl:flex items-center gap-x-2 px-5 py-2.5 hover:bg-[--green] cursor-pointer' onClick={() => toggleNavOpts(0)}>
                                            <span>Products</span>
                                            <div> <Chevron className="fill-current" /> </div>
                                        </Link>


                                        {/* Small Screen */}
                                        <span className='flex xl:hidden items-center justify-between p-4' onClick={() => toggleNavOpts(0)}>
                                            <Link to="/products">Products</Link>
                                            {navOpts[0] ? <img src={navMinus} alt='Close icon' /> : <img src={navPlus} alt='Open icon' />}
                                        </span>


                                        <div className={`xl:absolute top-[42px] left-0 bg-white text-black z-10 pt-2 pb-4 xl:py-0 scale-up-ver-top dropdown-menu dropdown-shadow
                                            ${navOpts[0] ? 'block' : 'hidden'}
                                            `}
                                        >

                                            <div className='max-xl:hidden bg-[#222222] h-2.5' />

                                            <ul className='xl:py-2'>
                                                {navProds?.map((product, i) => (
                                                    <li key={product.category} className='drop-sub-hover'>

                                                        <div className='flex' onClick={() => toggleSubLinks(i)}>
                                                            <span className='h-full w-2 max-xl:hidden' />

                                                            {product.items ?

                                                                // Category with multiple items {Ink, stands, kiosk table, etc}
                                                                <span className={`pl-8 pr-4 sm:px-5 py-2 sm:py-2.5 xl:border-b sm:hover:bg-[--green-hover] w-full xl:w-[--nav-link-w] inline-flex justify-between items-center ${product.isOpen && 'max-sm:bg-[#32b3a01a]'}`}>
                                                                    <span>{product.category}</span>
                                                                    <div className={`max-xl:transition-all max-sm:size-3 xl:-rotate-90 ${product.isOpen && 'max-xl:-rotate-180'}`}>
                                                                        <Chevron className="fill-[--black-bg] size-3" />
                                                                    </div>
                                                                </span>
                                                                :

                                                                // Category with single item {wipes and eyelets}
                                                                <Link to={`/products/${product.item.category}/${product.item.id}`}
                                                                    onClick={(e) => handleRedirection(
                                                                        e,
                                                                        `/products/${product.item.category}/${product.item.id}`
                                                                    )}
                                                                    className='pl-8 pr-4 sm:px-5 py-2 sm:py-2.5 xl:border-b sm:hover:bg-[--green-hover] w-full xl:w-[--nav-link-w] inline-block'
                                                                >
                                                                    {product.category}
                                                                </Link>
                                                            }

                                                            <span className='h-full w-2 max-xl:hidden' />
                                                        </div>


                                                        {product.items &&
                                                            <ul
                                                                className={`xl:absolute left-[266px] bg-white text-black z-10 py-2  xl:px-2 xl:divide-y dropdown-sub dropdown-sub-shadow
                                                            ${product.isOpen ? 'block max-xl:bg-[#32b3a00d]' : 'hidden'} scale-up-ver-top`}
                                                                style={{ top: product.top }}
                                                            >

                                                                {product.items?.map(item => (
                                                                    <li key={item.id}>
                                                                        <Link to={`/products/${item.category}/${item.id}`}
                                                                            onClick={(e) => handleRedirection(e, `/products/${item.category}/${item.id}`)}
                                                                            className="pr-2 pl-16 xl:px-5 py-2 xl:py-2.5 hover:bg-[--green-hover] w-full xl:w-[--nav-link-w] inline-block">
                                                                            {item.name}
                                                                        </Link>
                                                                    </li>
                                                                ))}

                                                            </ul>
                                                        }
                                                    </li>
                                                ))}

                                            </ul>
                                        </div>
                                    </div>



                                    {/* Resources */}
                                    <div className='relative dropdown max-xl:border-b max-xl:w-full'>

                                        <span className='flex items-center max-xl:justify-between xl:gap-x-2 p-4 xl:px-5 xl:py-2.5 xl:hover:bg-[--green] xl:cursor-pointer' onClick={() => toggleNavOpts(1)}>
                                            <span>Resources</span>
                                            <div className='max-xl:hidden'> <Chevron className="fill-current" /> </div>
                                            {navOpts[1] ?
                                                <img src={navMinus} alt='Close icon' className='xl:hidden' /> :
                                                <img src={navPlus} alt='Open icon' className='xl:hidden' />
                                            }
                                        </span>


                                        <div className={`xl:absolute top-[42px] left-0 bg-white text-black z-10 pt-2 pb-4 xl:py-0 scale-up-ver-top dropdown-menu dropdown-shadow ${navOpts[1] ? 'block' : 'hidden'}`}>
                                            <div className='max-xl:hidden bg-[#222222] h-2.5' />
                                            <ul className='xl:py-2'>
                                                <li className='xl:px-2'>
                                                    <Link to="/gallery" className='pl-8 pr-4 xl:px-5 py-2 xl:py-2.5 xl:border-b xl:hover:bg-[--green-hover] w-full xl:w-[--nav-link-w] inline-block'>Gallery</Link>
                                                </li>

                                                <li className='xl:px-2'>
                                                    <a href={broucher} rel="noreferrer" target='_blank' className='pl-8 pr-4 xl:px-5 py-2 xl:py-2.5 xl:border-b xl:hover:bg-[--green-hover] w-full xl:w-[--nav-link-w] inline-block'>Company Brochure</a>
                                                </li>

                                                <li className='xl:px-2'>
                                                    <Link to='/privacy-policy' className='pl-8 pr-4 xl:px-5 py-2 xl:py-2.5 xl:hover:bg-[--green-hover] w-full xl:w-[--nav-link-w] inline-block'>Privacy and Cookies Policy</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>



                                    <Link to='/contact-us' className='max-xl:w-full p-4 xl:px-5 xl:py-2.5 hover:bg-[--green] max-xl:border-b'>Contact Us</Link>
                                </div>





                                {/* IPAD and Desktop - Search */}
                                <div className='hidden sm:flex justify-end relative group'>
                                    <input type="text"
                                        className='rounded-[10px] w-0 py-2 group-hover:pl-5 group-hover:pr-14 group-hover:w-[378px] focus-visible:py-2 focus-visible:pl-5 focus-visible:pr-14 focus-visible:w-[378px] peer transition-all'
                                        name='searchProducts'
                                        placeholder='i.e. Standee'
                                        value={query}
                                        ref={inputRef}
                                        onFocus={handleFocus}
                                        onBlur={handleBlur}
                                        onChange={handleSearchChange} // Update search query on change
                                    />

                                    <div className='group-hover:absolute peer-focus-visible:absolute top-1/2 right-4 group-hover:-translate-y-1/2 peer-focus-visible:-translate-y-1/2 text-white group-hover:text-black peer-focus-visible:text-black hover:text-black flex items-center gap-x-1'>
                                        {(query && isInputFocused) &&
                                            <img src={close} alt="Remove query" title='Remove query'
                                                className='cursor-pointer opacity-50 z-30'
                                                onClick={handleCloseClick}
                                            />
                                        }
                                        <Search className='fill-current' />
                                    </div>
                                </div>



                                {/* Mobile - Search Starts */}
                                <div onClick={() => setToggleSearch(true)} className='sm:hidden'>
                                    <Search className='fill-white' />
                                </div>
                                <img src={navMenu} alt='Hamburger icon' className='xl:hidden' onClick={() => setShowNav(true)} />

                                {toggleSearch && <div className='h-screen w-full absolute top-0 left-0 z-10'>
                                    <div className='size-full bg-[#222222cb] z-20' onClick={closeNavSearchMblScreen} />
                                    <div className='absolute top-5 left-4 z-30 slide-in-top flex gap-x-2' style={{ width: 'calc(100% - 40px)' }}>
                                        <form className='relative flex-grow'>
                                            <input
                                                className='rounded-[10px] py-2 pl-5 pr-14 w-full focus-visible:outline-black'
                                                name='searchProducts'
                                                placeholder='Search Products'
                                                value={query}
                                                ref={inputRef}
                                                onFocus={handleFocus}
                                                onBlur={handleBlur}
                                                onChange={handleSearchChange} // Update search query on change
                                            />
                                            <div className='absolute top-1/2 right-4 -translate-y-1/2 flex items-center gap-x-1'>
                                                {(query && isInputFocused) &&
                                                    <img src={close} alt="Remove query" title='Remove query'
                                                        className='cursor-pointer opacity-50'
                                                        onClick={handleCloseClick}
                                                    />
                                                }
                                                <Search className='size-6 fill-[--black-bg]' />
                                            </div>
                                        </form>
                                        <img src={closeWhite} alt='Close icon' onClick={closeNavSearchMblScreen} />
                                    </div>
                                </div>}
                                {/* Mobile - Search Ends */}

                            </div>
                        </div>


                        {isInputFocused && <NavSearchResult query={query} results={results} handleRedirection={handleRedirection} />}
                    </nav>
                </div>}
        </>
    )
}