import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { highlightKeyword } from '../utils/highlightKeyword'
import { notFoundSVG } from '../assets'

export const NavSearchResult = ({ query, results, handleRedirection }) => {
    const [rightSearchResult, setRightSearchResult] = useState(0)
    const [height, setHeight] = useState('auto')

    useEffect(() => {
        let width = document.body.offsetWidth
        if (width > 1440) setRightSearchResult(120 + ((width - 1440) / 2))
        else if (width >= 768) setRightSearchResult(60)
        else setRightSearchResult(0)

    }, [])


    const handleSeeAll = (e, path) => {
        e.preventDefault();
        setTimeout(() => handleRedirection(e, path), 0);
    };


    return (
        <>
            {query && (
                <ul
                    className='bg-white py-4 absolute z-20 w-full sm:w-[378px] sm:rounded-[10px] nav-search-shadow overflow-y-auto'
                    style={{
                        right: `${rightSearchResult}px`,
                    }}
                >
                    {results ? (
                        results.slice(0, 8).map((result, index) => (
                            <li key={index}>
                                <Link onClick={(e) => handleRedirection(e, `/products/${result.category}/${result.id}`)}
                                    className='p-4 flex items-center gap-x-3 hover:bg-[#3333330d]'>
                                    <img src={result.img[0]} alt={result.name} className='size-10' />
                                    <div>
                                        <p className='font-semibold'>{result.name}</p>
                                        <p>{highlightKeyword(result.description, query)}</p>
                                    </div>
                                </Link>
                            </li>
                        ))
                    ) : (
                        <li className="text-center">
                            <img src={notFoundSVG} alt="No results found" className="mx-auto mb-4" height={100} width={100} />
                            <h5>We couldn't find any matches for your search. Try different keywords.</h5>
                        </li>
                    )}

                    {results?.length > 10 &&
                        <li className='px-2.5 pt-2.5'>
                            <Link
                                to={`/products/search?query=${encodeURIComponent(query)}`}
                                onClick={(e) => handleSeeAll(e, `/products/search?query=${encodeURIComponent(query)}`)}
                                className='py-2 text-center bg-[#F4F4F4] hover:bg-[#EBEBEB] block font-bold'
                            >See all ({results.length})</Link>
                        </li>
                    }
                </ul>
            )}
        </>
    )
}