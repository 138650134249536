import React from "react";
import { Link } from "react-router-dom";

import { insta1, insta2, insta3, insta4, insta5 } from "../assets";

export const FollowInstagram = ({ backGround }) => {
  return (
    <section className={`bg-[${backGround}]`}>
      <div className="px-5 sm:px-[--padding-x-sm] xl:px-[--padding-x-lg] py-9 sm:py-14 xl:py-[--padding-y-lg] space-y-8 sm:space-y-6 xl:space-y-8 center">
        <div className="space-y-2">
          <h3>Follow our Instagram</h3>
          <p className="text-[#949494]">
            Our Official Instagram account
            <a
              href="https://www.instagram.com/muhammadshehryarsk/"
              target="_blank"
            >
              @skprintingsolutions
            </a>
            and hashtag #skprintingsolutions
          </p>
        </div>


                <div className='grid grid-cols-3 max-sm:gap-2.5 max-xl:gap-6 xl:flex'>
                    <a href="https://www.instagram.com/sk_printingsolution" title='Go to Instgram' className='w-full'>
                        <img src={insta1} alt="Instagram feed 1" />
                    </a>
                    <a href="https://www.instagram.com/sk_printingsolution" title='Go to Instgram' className='w-full'>
                        <img src={insta2} alt="Instagram feed 2" />
                    </a>
                    <a href="https://www.instagram.com/sk_printingsolution" title='Go to Instgram' className='w-full'>
                        <img src={insta3} alt="Instagram feed 3" />
                    </a>
                    <a href="https://www.instagram.com/sk_printingsolution" title='Go to Instgram' className='w-full'>
                        <img src={insta4} alt="Instagram feed 4" />
                    </a>
                    <a href="https://www.instagram.com/sk_printingsolution" title='Go to Instgram' className='w-full'>
                        <img src={insta5} alt="Instagram feed 5" />
                    </a>
                    <a href="https://www.instagram.com/sk_printingsolution" title='Go to Instgram' className='xl:hidden w-full'>
                        <img src={insta2} alt="Instagram feed 6" />
                    </a>
                </div>
            </div>
        </section>
    )
}