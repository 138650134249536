import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Arrow, Header, NavScroll, Navbar, Footer } from "../Components/index";

import { aboutCta, aboutHeroBelow, aboutHeroFront, heroAbout, aboutService1, aboutService2, aboutService3, aboutService4, aboutService5, star, aboutStorybg } from "../assets";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";

const services = [
  {
    title: "Specialty Inks and Materials",
    desc: "We use a diverse range of top-quality inks including Galaxy eco-solvent, Flora, and Drop Konika, ensuring vivid colors and long-lasting durability for every project.",
    icon: aboutService1,
  },
  {
    title: "Rollup Stands",
    desc: "Available in various sizes such as 3ft and 5ft, our rollup stands are perfect for exhibitions, presentations, and promotional events, combining ease of use with excellent visibility.",
    icon: aboutService2,
  },
  {
    title: "Panda X Stands & Kiosk Tables",
    desc: "Enhance your promotional activities with our sturdy and eye-catching panda stands and kiosk tables, ideal for both indoor and outdoor settings.",
    icon: aboutService3,
  },
  {
    title: "Digital & Offset Printing",
    desc: "From vibrant brochures to impactful posters, professional Panaflex to engaging flyers, and custom packaging solutions, our digital and offset printing services ensure high-quality results that make your brand stand out.",
    icon: aboutService4,
  },
  {
    title: "3D Signage",
    desc: "Elevate your brand presence with our custom 3D signage solutions, designed to make a striking impact and leave a lasting impression.",
    icon: aboutService5,
  },
];
const reviews = [
  {
    name: "Sidra Shaheen",
    desc: "It was a great experience. The most affordable and efficient work I have ever seen ..highly recommended",
    color: "#479945"
  },
  {
    name: "Home classes",
    desc: "Good Quality Display Promotion Stand",
    color: "#DB377B"
  },
  {
    name: "Muhammad Zeeshan",
    desc: "Very affordable and competitive prices for high quality printing products. No disappointment when buying Standee and display stands. Thank you Sk Printing Solutions for the great services.",
    color: "#4462FF"
  },
  {
    name: "Faizan",
    desc: "I was blown away by the quality of the 3D signage provided by this company. The attention to detail and the vibrant colors made the logo stand out beautifully. Highly recommend their services!",
    color: "#F9752C"
  }
];

export const AboutUs = () => {
  const [padding, setPadding] = useState(120);

  useEffect(() => {
    let width = document.body.offsetWidth
    if (width > 1440) setPadding(padding + ((width - 1440) / 2))
    else if (width >= 768) setPadding(60)
    else setPadding(20)

    window.scrollTo(0, 0);
  }, []);

  return (
    <main>
      {/* Header */}
      <Header />

      {/* Navbar */}
      <Navbar />

      {/* Navbar on scroll */}
      <NavScroll />




      {/* Hero Section */}
      <section style={{ backgroundImage: `url(${heroAbout})`, paddingLeft: `${padding}px` }}
        className="h-[300px] sm:h-[400px] flex items-center text-white bg-no-repeat bg-center bg-cover relative overflow-hidden">
        <h1 className="z-10">About Us</h1>
        <div className="absolute right-0 bottom-0 flex justify-center xl:justify-end items-end w-full px-5 sm:px-[--padding-x-sm] pb-5 h-full center">
          <img src={aboutHeroFront} alt="hero front details" className="w-72 sm:w-96 xl:w-[400px] absolute top-1/2 -translate-y-1/2" style={{ right: `${padding}px` }} />
          <img src={aboutHeroBelow} alt="hero below details" className="w-[720px] xl:absolute" style={{ right: `${padding}px` }} />
        </div>
      </section>




      {/* Introduction */}
      <section className="px-5 sm:px-[--padding-x-sm] xl:px-[--padding-x-lg] max-sm:py-9 max-xl:py-14 max-xl:space-y-3 xl:h-[300px] xl:flex justify-center items-center flex-col gap-y-3 text-center center">
        <h1>Introduction</h1>
        <p className="xl:px-52">
          Welcome to <span className="font-semibold">SK Printing Solution</span>, where your visions come alive on paper and beyond. For over a decade, we've been at the forefront of the printing industry, providing innovative and eco-friendly solutions that empower businesses and individuals to communicate their message effectively.
        </p>
      </section>




      {/* Story */}
      <section className="bg-cover bg-center" style={{ backgroundImage: `url(${aboutStorybg})` }}>
        <div className="px-5 sm:px-[--padding-x-sm] xl:px-[--padding-x-lg] py-9 sm:py-14 xl:py-[--padding-y-lg] space-y-9 sm:space-y-14 xl:space-y-[--padding-y-lg] text-center center">

          <div className="space-y-3 xl:px-52">
            <h1>Our Story</h1>
            <p>
              <span className="font-semibold">SK Printing Solution</span>, founded in 2019, started as a small shop in Karachi with a passion for impactful print. Over the years, we've expanded our services and embraced technology to offer a broad spectrum of printing options—from traditional offset to digital printing. Each step in our journey has been guided by our commitment to quality and sustainability.
            </p>
          </div>

          <iframe frameborder="0"
            src="https://www.youtube-nocookie.com/embed/aCdyrQTwsyY?si=dm4ixv9FgFXnC30w"
            className="mx-auto w-[335px] h-[250px] sm:w-[648px] sm:h-[364px] xl:w-[800px] xl:h-[449px]"
            title="SK Printing Solution"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
            referrerpolicy="strict-origin-when-cross-origin" />

        </div>
      </section>




      {/* Mission */}
      <section className="max-sm:px-5 py-9 sm:py-14 xl:py-[--padding-y-lg] center">

        <div className="bg-[--black-bg] sm:w-[648px] xl:w-[994px] p-8 sm:p-9 xl:p-12 sm:flex items-center gap-x-6 xl:gap-x-10 text-white border-b-[10px] border-[--green] mx-auto">

          <h1 className='max-xl:pb-2 xl:pl-10 sm:w-[204px] xl:w-[300px] sm:border-r flex-shrink-0 relative after:content-[""] after:absolute after:left-0 xl:after:left-10 after:bottom-0 after:w-32 after:h-1 after:bg-[--green]'>
            Our Mission
          </h1>

          <p className="max-sm:mt-9">
            Our mission is to deliver top-notch printing solutions that not only
            meet but exceed our clients' expectations. We are committed to
            innovation, customer satisfaction, and environmental stewardship,
            ensuring every print job is a step towards a more sustainable
            future.
          </p>
        </div>

      </section>




      {/* Services */}
      <section className="bg-[#F4EEE5]">
        <div className="px-5 sm:px-[--padding-x-sm] xl:px-[--padding-x-lg] py-9 sm:py-14 xl:py-[--padding-y-lg] center">

          <div className="space-y-3 xl:px-52 text-center">
            <h1>Our Services</h1>
            <p>
              At SK Printing Solutions, we pride ourselves on a wide array of
              printing services tailored to meet every business need. Our
              offerings include:
            </p>
          </div>

          <div className="sm:flex flex-wrap justify-center gap-x-6 gap-y-16 xl:gap-x-[31px] xl:gap-y-[71px] max-sm:space-y-[54px] mt-[54px] sm:mt-[76px] xl:mt-[88px]">
            {services.map((service) => (
              <div key={service.title} className="bg-white sm:w-[45%] xl:w-[30%] px-5 sm:px-8 pt-[52px] sm:pt-16 pb-[34px] sm:pb-8 max-sm:space-y-3 flex-shrink-0 sm:flex flex-col gap-3 items-end justify-between border-b-[10px] border-[--green] relative">

                <img src={service.icon} alt="Name icon" className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 size-[--padding-x-sm] sm:size-20" />

                <div className="space-y-3 sm:space-y-6">
                  <h3>{service.title}</h3>
                  <p>{service.desc}</p>
                </div>

                <Link to="/products" className="flex gap-x-2 max-sm:justify-end">
                  <span className="font-semibold">View Products</span>
                  <Arrow className="fill-current -rotate-45" />
                </Link>

              </div>
            ))}
          </div>

          <p className="xl:px-52 text-center mt-6 sm:mt-9 xl:mt-12">
            Each service is backed by our commitment to quality and customer
            satisfaction. Utilizing state-of-the-art technology and innovative
            techniques, we help bring your creative visions to life.
          </p>

        </div>
      </section>




      {/* Why Choose Us */}
      <section className="max-sm:px-5 max-xl:px-[--padding-x-sm] py-9 sm:py-14 lg:py-[--padding-y-lg] center">

        <div className="bg-[--black-bg] px-5 py-6 sm:p-9 xl:p-12 space-y-6 sm:space-y-9 xl:space-y-10 xl:w-[994px] border-b-[10px] border-[--green] xl:mx-auto">
          <div className="space-y-3 text-white text-center">
            <h1>Why Choose Us</h1>
            <p>
              Our mission is to deliver top-notch printing solutions that not only meet but exceed our clients' expectations. We are committed to innovation, customer satisfaction, and environmental stewardship, ensuring every print job is a step towards a more sustainable future.
            </p>
          </div>

          <Swiper
            className="mySwiper about-testimonial !pb-8"
            pagination={{ clickable: true }}
            loop={true}
            modules={[Pagination]}
            spaceBetween={32}
            breakpoints={{
              0: { slidesPerView: 1 },
              1440: { slidesPerView: 3 }
            }}
          >
            {reviews.map((review, index) => (
              <SwiperSlide key={index}>
                <div className="bg-white p-5 space-y-4">
                  <div className="flex gap-x-2.5 items-center">
                    <span className="rounded-full size-[50px] flex justify-center items-center text-[32px] leading-8 text-white" style={{ backgroundColor: review.color }}>
                      {review.name.charAt(0)}
                    </span>
                    <div className="space-y-1">
                      <p className="font-semibold">{review.name}</p>
                      <div className="flex gap-x-[5px]">
                        <img src={star} alt="Star icon" />
                        <img src={star} alt="Star icon" />
                        <img src={star} alt="Star icon" />
                        <img src={star} alt="Star icon" />
                        <img src={star} alt="Star icon" />
                      </div>
                    </div>
                  </div>

                  <p>{review.desc}</p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

      </section>




      {/* CTA */}
      <section className="bg-no-repeat bg-[#000000cc] bg-blend-multiply bg-center bg-cover"
        style={{ backgroundImage: `url(${aboutCta})` }}>
        <div className="px-5 sm:px-[--padding-x-sm] xl:p-[--padding-x-lg] max-sm:py-12 max-xl:py-14 space-y-6 sm:space-y-10 center">

          <div className="text-center text-white space-y-2 xl:px-[--padding-x-lg]">
            <h3> Ready to start your printing project? </h3>
            <p> Contact us today to get a custom quote or visit our products page to learn more about what we can do for you. </p>
          </div>

          <Link to="/contact-us" className="button-black bg-white text-black hover:text-white block mx-auto w-fit">
            Contact Now
          </Link>

        </div>
      </section>




      {/* Footer */}
      <Footer />


    </main>
  );
};

export default AboutUs;
