import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Arrow, Header, NavScroll, Navbar, Footer } from "../Components/index";
import { searchContent } from "../utils/searchContent";


export const SearchProduct = () => {
    const [searchProducts, setSearchProducts] = useState([])
    const [query, setQuery] = useState("")
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
        const searchQuery = new URLSearchParams(location.search).get('query');
        setQuery(searchQuery)
        setSearchProducts(searchContent(searchQuery))
    }, [location]);


    return (
        <main>
            {/* Header */}
            <Header />




            {/* Navbar */}
            <Navbar />

            <NavScroll />





            {/* Heading */}
            <div className="py-4 text-center bg-[#3333330d]">
                <h3>Search Results</h3>
                <p>{searchProducts?.length} results for "{query}"</p>
            </div>





            {/* Products */}
            <section className="px-5 sm:px-[--padding-x-sm] xl:px-[--padding-x-lg] py-9 sm:pt-9 xl:pt-12 sm:pb-14 xl:pb-[--padding-y-lg] grid sm:grid-cols-2 xl:grid-cols-4 gap-8 sm:gap-6 xl:gap-8 center">

                {searchProducts.map(product => (
                    <div key={product.id}
                        className='border divide-y cursor-pointer overflow-hidden'
                        onClick={() => navigate(`/products/${product.category}/${product.id}`)}
                    >

                        <img src={product.img[0]} alt={product.name} title={product.name}
                            className="hover:scale-110 transition-transform duration-300 peer"
                        />

                        <div className="p-4 flex justify-between items-center z-10 xl:relative peer-hover:z-10 peer-hover:relative bg-white">
                            <div>
                                <h5>{product.name}</h5>
                                <p className="text-[#232b2bcc]">{product.model || product.made || product.type}</p>
                                <p className="text-[#232b2bcc] font-bold">{product.price}</p>
                            </div>
                            <span> <Arrow className="fill-[--black-bg]" /> </span>
                        </div>

                    </div>
                ))}
                
            </section>




            {/* Footer */}
            <Footer />
        </main >
    );
};
