import { FacebookIcon, InstagramIcon, LinkedInIcon, TiktokIcon, TwitterIcon, YouTubeIcon } from "../Components"
const socialLinks = [
    {
        site: "Twitter",
        link: "http://www.twitter.com/skprintingsol",
        icon: TwitterIcon
    },
    {
        site: "Facebook",
        link: "https://www.facebook.com/SkPrintingSolution",
        icon: FacebookIcon
    },
    {
        site: "Youtube",
        link: "https://www.youtube.com/@sk_printingsolution",
        icon: YouTubeIcon
    },
    {
        site: "Tiktok",
        link: "https://www.tiktok.com/@sk.printing.solution",
        icon: TiktokIcon
    },
    {
        site: "Instagram",
        link: "https://www.instagram.com/sk_printingsolution",
        icon: InstagramIcon
    },
    {
        site: "LinkedIn",
        link: "https://www.linkedin.com/company/skprintingsolution",
        icon: LinkedInIcon
    },
]

export default socialLinks