// FacebookIcon.jsx
import React from 'react';

export const FacebookIcon = ({ className }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      viewBox='0 0 32 32'
      fill='currentColor'
    >
      <path d="M20.4804 17.0696L20.986 13.7732H17.8233V11.6351C17.8233 10.733 18.2643 9.85327 19.6808 9.85327H21.1196V7.04694C21.1196 7.04694 19.8144 6.82422 18.5672 6.82422C15.9635 6.82422 14.2597 8.40111 14.2597 11.2609V13.7732H11.3643V17.0696H14.2597V25.0386C14.841 25.13 15.4357 25.1767 16.0415 25.1767C16.6473 25.1767 17.242 25.1277 17.8233 25.0386V17.0696H20.4804Z" />
    </svg>
  );
};