import React, { useEffect } from 'react';
import { Footer, Header, NavScroll, Navbar } from '../Components/index';

export const PrivacyPolicy = () => {

    useEffect(() => window.scrollTo(0, 0), []);

    return (
        <main>
            {/* Header */}
            <Header />



            {/* Navbar */}
            <Navbar />



            {/* Navbar on scroll */}
            <NavScroll />



            {/* Privacy Policy */}
            <section className='px-5 sm:px-[--padding-x-sm] xl:px-[--padding-x-lg] py-9 sm:pt-9 xl:pt-12 sm:pb-14 xl:pb-[--padding-y-lg] space-y-6 center'>

                <h1>Privacy Policy</h1>

                <p className='font-semibold'>Last updated: May 11, 2024</p>

                <p>This Privacy Policy describes our policies and procedures on the collection, use, and disclosure of your information when you use our Service and informs you about your privacy rights and how the law protects you.</p>

                <div className='space-y-2'>
                    <h2>Data Collection and Use</h2>
                    <p>We collect several types of information for various purposes to provide and improve our Service to you.</p>
                </div>


                <h3>Types of Data Collected</h3>

                <div className='space-y-1'>
                    <h4>Personal Data</h4>
                    <div>
                        <p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to:</p><br />
                        <ul>
                            <li>Name</li>
                            <li>Email address</li>
                            <li>Phone number</li>
                            <li>Details provided in the text boxes of our forms</li>
                        </ul>
                    </div>
                </div>

                <div className='space-y-1'>
                    <h4>Usage Data</h4>
                    <p>Usage Data is collected automatically when using the Service. This may include information such as your device's Internet Protocol address (e.g., IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
                </div>


                <div className='space-y-1'>
                    <h3>Use of Your Personal Data</h3>
                    <ul>
                        <li><strong>To provide and maintain our Service:</strong> including to monitor the usage of our Service.</li>
                        <li><strong>To contact you:</strong> To contact you by email, telephone calls, SMS, or other equivalent forms of electronic communication regarding updates or informative communications related to the functionalities, products or contracted services, including security updates.</li>
                        <li><strong>To provide you:</strong> with news, special offers, and general information about other goods, services, and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information.</li>
                        <li><strong>To manage your requests:</strong> To attend and manage your requests to us.</li>
                    </ul>
                </div>


                <div className='space-y-1'>
                    <h3>Retention of Your Personal Data</h3>
                    <p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our legal agreements and policies.</p>
                </div>


                <div className='space-y-1'>
                    <h3>Transfer of Your Personal Data</h3>
                    <p>Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</p>
                </div>


                <div className='space-y-1'>
                    <h3>Disclosure of Your Personal Data</h3>
                    <p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
                </div>


                <div className='space-y-1'>
                    <h3>Security of Your Personal Data</h3>
                    <p>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>
                </div>



                <div className='space-y-2'>
                    <h2>Children's Privacy</h2>
                    <p>Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.</p>
                </div>



                <div className='space-y-2'>
                    <h2>Links to Other Websites</h2>
                    <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit. We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
                </div>



                <div className='space-y-2'>
                    <h2>Changes to This Privacy Policy</h2>
                    <p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
                </div>




                <h2>Contact Us</h2>
                <div>
                    <p>If you have any questions about this Privacy Policy, you can contact us:</p><br />
                    <ul>
                        <li>By email: contact@skprintingsolution.com</li>
                        <li>By visiting this page on our website: <a className='underline' href="https://skprintingsolution.com/" target='_blank'>https://skprintingsolution.com/</a></li>
                        <li>By phone number: +92 310 2806759</li>
                    </ul>
                </div>

            </section>



            {/* Footer */}
            <Footer />
        </main>
    );
};
