import React from 'react'

export const Email = ({ fill }) => {
    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_828_523)">
                <path d="M15.0938 2.375H1.90625C1.12928 2.375 0.5 3.00819 0.5 3.78125V12.2188C0.5 12.9964 1.13391 13.625 1.90625 13.625H15.0938C15.8642 13.625 16.5 12.9991 16.5 12.2188V3.78125C16.5 3.00956 15.8732 2.375 15.0938 2.375ZM14.8968 3.3125L9.49438 8.68647C9.22875 8.95209 8.87563 9.09834 8.5 9.09834C8.12437 9.09834 7.77125 8.95206 7.50475 8.68559L2.10319 3.3125H14.8968ZM1.4375 12.0279V3.97266L5.48869 8.0025L1.4375 12.0279ZM2.10378 12.6875L6.15338 8.66366L6.84272 9.34937C7.28541 9.79206 7.87397 10.0358 8.5 10.0358C9.12603 10.0358 9.71459 9.79206 10.1564 9.35025L10.8466 8.66366L14.8962 12.6875H2.10378ZM15.5625 12.0279L11.5113 8.0025L15.5625 3.97266V12.0279Z" />
            </g>
            <defs>
                <clipPath id="clip0_828_523">
                    <rect width="16" height="16" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>

    )
}