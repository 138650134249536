// LinkedInIcon.jsx
import React from 'react';

export const LinkedInIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 33 32"
      className={className}
      fill="currentColor"
    >
      <path d="M23.7193 23.7187H20.6412V18.8538C20.6412 17.6907 20.6169 16.2001 19.0413 16.2001C17.4333 16.2001 17.1897 17.4614 17.1897 18.7718V23.7187H14.1035V13.7103H17.0597V15.0781H17.1003C17.7013 14.0379 18.8221 13.4155 20.0159 13.4646C23.1345 13.4646 23.7111 15.5367 23.7111 18.2313L23.7193 23.7187Z" />
      <path d="M9.93661 11.5392C9.05517 11.5392 8.33398 10.8253 8.33398 9.93661C8.33398 9.04788 9.05517 8.33398 9.93661 8.33398C10.8181 8.33398 11.5392 9.05517 11.5392 9.93661C11.5392 10.3591 11.3717 10.7671 11.073 11.073C10.7743 11.3717 10.3664 11.5392 9.93661 11.5392Z" />
      <path d="M11.5392 13.4619H8.33398V23.7187H11.5392V13.4619Z" />
    </svg>
  );
};