import React, { useEffect, useState } from 'react'

import { Chevron } from "./index";

export const GoToTop = () => {
    const [scrollTop, setScrollTop] = useState(false)

    useEffect(() => window.addEventListener('scroll', () => window.pageYOffset > 1300 ? setScrollTop(true) : setScrollTop(false)), [])


    return (
        <>
            {scrollTop && <div className='bg-[--green] rounded-t-[30px] px-6 sm:px-8 pt-4 pb-2 sm:py-4 w-fit fixed bottom-0 left-1/2 -translate-x-1/2 z-10 flex items-center gap-x-[11px] cursor-pointer' style={{ boxShadow: '0 -4px 20px #0003' }} onClick={() => window.scrollTo(0, 0)}>
                <div className="rotate-180"> <Chevron className="size-4 fill-white" /> </div>
                <span className='text-white max-sm:hidden'>To the Top</span>
            </div>}
        </>
    )
}