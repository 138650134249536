import { dp001, dp002, ecoSolvent, eye001, ink001, ink002, ink002_1, ink002_2, ink002_3, ink002_4, ink004, ink004_1, ink004_2, ink004_3, ink004_4, ink006, ink008, ink010, ink010_1, ink010_2, ink010_3, ink010_4, ink012, ink012_1, ink014, ksk001, op001, op001_1, op001_2, op002, op002_1, op003, op003_1, op003_2, op004, rls001, rls001_1, rls002, rls002_1, wps001, xst001, xst001_1, xst001_2, xst001_3, xst001_4, xst001_5, xst001_6, xst002, xst003, xst003_1, xst003_2, xst003_3 } from '../assets'

export const products = [
    {
        category: "Ink",
        items: [
            {
                id: "ink001",
                category: "Ink",
                name: "Galaxy Eco solvent",
                description: "Galaxy Eco solvent DX5, DX4 1000mL",
                model: "DX5, DX4",
                quantity: "1000mL",
                details: ["GALAXY Eco solvent Ink for Wide Format,Grand Format Printers with DX4 DX5 DX7 DX9 XP600 TX800 Printhead", "Mimaki", "Roland", "Mutoh", "CMYK (4 Liters)"],
                img: [ink001],
                price: 4000
            },
            {
                id: "ink002",
                category: "Ink",
                name: "STARFIRE INK",
                description: "STARFIRE INK 10 PL 1000mL",
                model: "10 PL",
                quantity: "1000mL",
                details: ["Starfire 10pl", "Carben free", "Don't be blockage", "Slightly smell", "Outdoor life upto 1 year", "Brilliant color sharpness and shining", "Completely free of Hazardous Air Pollutants", "Minimum maintenance whether the printer is in use or idle"],
                img: [ink002, ink002_1, ink002_2, ink002_3, ink002_4],
                price: 2200
            },
            {
                id: "ink003",
                category: "Ink",
                name: "STARFIRE SOLV",
                description: "STARFIRE SOLV 10 PL 1000mL",
                model: "10 PL",
                quantity: "1000mL",
                details: ["Starfire 10pl", "Carben free", "Don't be blockage", "Slightly smell", "Outdoor life upto 1 year", "Brilliant color sharpness and shining", "Completely free of Hazardous Air Pollutants", "Minimum maintenance whether the printer is in use or idle"],
                img: [ecoSolvent],
                price: 2200
            },
            {
                id: "ink004",
                category: "Ink",
                name: "SK1 INFINITY INK",
                description: "SK1 INFINITY INK 12 PL 1000mL",
                model: "12 PL",
                quantity: "1000mL",
                details: ["Brand Name Infinity /Challenger", "Model Number SK1", "Product amer SK1", "Color CMYK", "Volume 1 Liter per bottle", "Ink Type Eco solvent Ink", "Suitable printhead SPT 1020 35PL / 510 35PL 50PL/ 508GS", "Shelf life 12 Monthhs", "condition 100% Original Brand New", "Packing Carton Boxes"],
                img: [ink004, ink004_1, ink004_2, ink004_3, ink004_4],
                price: 2200
            },
            {
                id: "ink005",
                category: "Ink",
                name: "SK1 INFINITY SOLV",
                description: "SK1 INFINITY SOLV 12 PL 1000mL",
                model: "12 PL",
                quantity: "1000mL",
                details: ["Brand Name Infinity /Challenger", "Model Number SK1", "Product amer SK1", "Color CMYK", "Volume 1 Liter per bottle", "Ink Type Eco solvent Ink", "Suitable printhead SPT 1020 35PL / 510 35PL 50PL/ 508GS", "Shelf life 12 Monthhs", "condition 100% Original Brand New", "Packing Carton Boxes"],
                img: [ecoSolvent],
                price: 2200
            },
            {
                id: "ink006",
                category: "Ink",
                name: "FLORA INK",
                description: "FLORA INK 15 PL 1000mL",
                model: "15 PL",
                quantity: "1000mL",
                details: ["Flora solvent ink for polaris 15PL printhead (1L packing)", "fluent printing, Never jam the nozzle", "environmentally friendly, strong weather resistance", "brilliant color, wide color gamut, and excellent printing", "good stablity", "high compatiability", "more than 18 months shelf time and less wear on the print heads"],
                img: [ink006],
                price: 2200
            },
            {
                id: "ink007",
                category: "Ink",
                name: "FLORA SOLV",
                description: "FLORA SOLV 15 PL 1000mL",
                model: "15 PL",
                quantity: "1000mL",
                details: ["Flora solvent ink for polaris 15PL printhead (1L packing)", "fluent printing, Never jam the nozzle", "environmentally friendly, strong weather resistance", "brilliant color, wide color gamut, and excellent printing", "good stablity", "high compatiability", "more than 18 months shelf time and less wear on the print heads"],
                img: [ecoSolvent],
                price: 2200
            },
            {
                id: "ink008",
                category: "Ink",
                name: "SGM INK",
                description: "SGM INK 25 PL 1000mL",
                model: "25 PL",
                quantity: "1000mL",
                details: ["SGM 25 PL INK",
                    "Ink Type Solvent",
                    "Color CMYK",
                    "Packaging Size 1 Litre",
                    "Printing Type Flex printing ink",
                    "Fast Drying Yes",
                    "Shelf Life 12 Months",
                    "Model Name SGM 1 25PL",
                    "Product madeription",
                    "Ghonghengz SGM 25pl ink"],
                img: [ink008],
                price: 2300
            },
            {
                id: "ink009",
                category: "Ink",
                name: "SGM SOLV",
                description: "SGM SOLV 25 PL 1000mL",
                model: "25 PL",
                quantity: "1000mL",
                details: [
                    "SGM 25 PL Solvent",
                    "Ink Type Solvent",
                    "Color CMYK",
                    "Packaging Size 1 Litre",
                    "Printing Type Flex printing ink",
                    "Fast Drying Yes",
                    "Shelf Life 12 Months",
                    "Model Name SGM 1 25PL",
                    "Product madeription",
                    "Ghonghengz SGM 25pl ink"
                ],
                img: [ecoSolvent],
                price: 2200
            },
            {
                id: "ink010",
                category: "Ink",
                name: "DROP KONICA 512I INK",
                description: "DROP KONICA 512I INK 30 PL 1000mL",
                model: "30 PL",
                quantity: "1000mL",
                details: ["DROP Solvent Based Ink", "Konica 512I solvent ink 1000ml"],
                img: [ink010, ink010_1, ink010_2, ink010_3, ink010_4],
                price: 2100
            },
            {
                id: "ink011",
                category: "Ink",
                name: "DROP KONICA 512I SOLV",
                description: "DROP KONICA 512I SOLV 30 PL 1000mL",
                model: "30 PL",
                quantity: "1000mL",
                details: ["DROP Solvent Based Ink", "Konica 512I solvent ink 1000ml"],
                img: [ecoSolvent],
                price: 2200
            },
            {
                id: "ink012",
                category: "Ink",
                name: "ALLWIN KONICA 512I INK",
                description: "ALLWIN KONICA 512I INK 30 PL 1000mL",
                model: "30 PL",
                quantity: "1000mL",
                details: [
                    "Allwin konica head 512i printing inks solvent ink from factory",
                    "low smell allwin ink for konica  512i 30pl printhead.",
                    "solvent based ink, ultraviolet light pigmented ink that is applied to various printers with konica printhead 512i",
                    "Wide color gamut and vivid color, high climate- resistant",
                    "By the best  materials, low odor, improve the print environment",
                    "Vibrant, brilliant and excellent color gamut, no - clogging problem",
                    "Good storage stability, heat resistance after a long period of cold tolerance test, long - term storage without flocculation, is conducive to ocean shipping",
                    "Suitable for printers with konica printhead"
                ],
                img: [ink012, ink012_1],
                price: 2100
            },
            {
                id: "ink013",
                category: "Ink",
                name: "ALLWIN KONICA 512I SOLV",
                description: "ALLWIN KONICA 512I SOLV 30 PL 1000mL",
                model: "30 PL",
                quantity: "1000mL",
                details: [
                    "Allwin konica head 512i printing inks solvent ink from factory",
                    "low smell allwin ink for konica  512i 30pl printhead.",
                    "solvent based ink, ultraviolet light pigmented ink that is applied to various printers with konica printhead 512i",
                    "Wide color gamut and vivid color, high climate - resistant",
                    "By the best  materials, low odor, improve the print environment",
                    "Vibrant, brilliant and excellent color gamut, no - clogging problem",
                    "Good storage stability, heat resistance after a long period of cold tolerance test, long - term storage without flocculation, is conducive to ocean shipping",
                    "Suitable for printers with konica printhead"
                ],
                img: [ecoSolvent],
                price: 2200
            },
            {
                id: "ink014",
                category: "Ink",
                name: "UV ECO SOLVENT INK",
                description: "UV ECO SOLVENT INK DX5, DX7, XP600 500mL",
                model: "DX5, DX7, XP600",
                quantity: "500mL",
                details: [
                    "Eco solvent Ink for Wide Format",
                    "Grand Format Printers with DX4 DX5 DX7 DX9 XP600 TX800 Printhead",
                    "Mimaki",
                    "Roland",
                    "Mutoh",
                    "(CMYK, 4 Liters)"
                ],
                img: [ink014],
                price: 3500
            },
        ]
    },
    {
        category: "Stands",
        items: [
            {
                id: "xst001",
                category: "Stands",
                name: "Panda x Stand",
                description: "Panda x Stand SK own brand 24 x 60 inches",
                made: "SK own brand",
                dimension: "24 x 60 inches",
                details: ["Foldable Design For 24 X 60 Inch Flex", "Iron Sticks for longer life", "Excellent Durability", "Un-Breakable, material Money Back Guarantee, Easy to Carry, Pure plastic meterial used", "Sk Own Brand With Good Quality Non Woven Blue Bag"],
                img: [xst001, xst001_1, xst001_2, xst001_3, xst001_4, xst001_5, xst001_6],
                price: 500
            },
            {
                id: "xst002",
                category: "Stands",
                name: "Smart x Stand",
                description: "Smart x Stand Made in Pakistan 24 x 60 inches",
                made: "Made in Pakistan",
                dimension: "24 x 60 inches",
                details: ["Durable Design With Economical Prices", "Iron Sticks for longer life", "Excellent Durability", "Un-Breakable material", "Money Back Guarantee", "Easy to Carry", "Pure plastic meterial used"],
                img: [xst002],
                price: 250
            },
            {
                id: "xst003",
                category: "Stands",
                name: "Table x Stand",
                description: "Table x Stand Made in Pakistan 9 x 14 inches",
                made: "Made in Pakistan",
                dimension: "9 x 14 inches",
                details: ["Mini Stand For Letter / A4 Sized Paper Or Flex", "Iron Sticks for longer life", "Excellent Durability", "Un-Breakable material", "Money Back Guarantee", "Easy to Carry", "Pure plastic meterial used"],
                img: [xst003, xst003_1, xst003_2, xst003_3],
                price: 150
            }
        ]
    },
    {
        category: "Rollup",
        items: [
            {
                id: "rls001",
                category: "Rollup",
                name: "2ft. Rollup",
                description: "2ft. Rollup Made in Pakistan 33 x 78 inches",
                made: "Made in Pakistan",
                dimension: "33 x 78 inches",
                details: ["Product details of Roll Up Stand flex stand for Advertising with Bag / Portable Aluminum Display Standee", "Highly Attractive to Get Audience Attraction", "Best Fit to Display your Products / Brands", "Ultimate option to Display in Exhibitions", "Smartly Fold-able and Portable", "Durable Material", "High Quality", "Change-able Display"],
                img: [rls001, rls001_1],
                price: 2300
            },
            {
                id: "rls002",
                category: "Rollup",
                name: "3ft. Rollup",
                description: "3ft. Rollup Made in Pakistan 24 x 61 inches",
                made: "Made in Pakistan",
                dimension: "24 x 61 inches",
                details: ["Product details of Roll Up Stand flex stand for Advertising with Bag / Portable Aluminum Display Standee", "Highly Attractive to Get Audience Attraction", "Best Fit to Display your Products / Brands", "Ultimate option to Display in Exhibitions", "Smartly Fold-able and Portable", "Durable Material", "High Quality", "Change-able Display"],
                img: [rls002, rls002_1],
                price: 2600
            }
        ]
    },
    {
        category: "Kiosk Table",
        items: [
            {
                id: "ksk001",
                category: "Kiosk Table",
                name: "Kiosk Table",
                description: "Kiosk Table Made in China 32 x 32 inches",
                made: "Made in China",
                dimension: "32 x 32 inches",
                details: ["Product details of promotion table stand / franchise stand / product display stand / table promotot", "Promotion table stand for effective product display", "Ideal for franchises and marketing events", "Sturdy and durable design for long-term use", "Convenient size for easy transportation and setup", "Suitable for digital goods and business services"],
                img: [ksk001],
                price: 11000
            }
        ]
    },
    {
        category: "Digital Printing",
        items: [
            {
                id: "dp001",
                category: "Digital Printing",
                name: "Posters",
                description: "Posters Customized",
                type: "Customized (Political | Event | Promotion | Social Media)",
                quantity: "Custom",
                details: ["PRINT MEDIA/SOCIAL MEDIA POSTER DESINING SERVICES"],
                img: [dp001],
                price: "Custom"
            },
            {
                id: "dp002",
                category: "Digital Printing",
                name: "Banners",
                description: "Banners Customized",
                type: "Customized (Political | Event | Promotion | Social Media)",
                quantity: "Custom",
                details: ["BANNERS DESIGNING SERVICES"],
                img: [dp002],
                price: "Custom"
            }
        ]
    },
    {
        category: "Offset Printing",
        items: [
            {
                id: "op001",
                category: "Offset Printing",
                name: "Business Cards",
                description: "Business Cards Customized",
                made: "Customized",
                quantity: "Custom",
                details: ["BUSINESS CARDS DESIGNING SERVICES"],
                img: [op001, op001_1, op001_2],
                price: "Custom"
            },
            {
                id: "op002",
                category: "Offset Printing",
                name: "Flyers",
                description: "Flyers Customized",
                made: "Customized",
                quantity: "Custom",
                details: ["FLYERS DESIGNING SERVICES"],
                img: [op002, op002_1],
                price: "Custom"
            },
            {
                id: "op003",
                category: "Offset Printing",
                name: "Packaging",
                description: "Packaging Customized",
                made: "Customized",
                quantity: "Custom",
                details: ["MULTIPLE CUSTOMIZED DESIGNING SERIVES"],
                img: [op003, op003_1, op003_2],
                price: "Custom"
            },
            {
                id: "op004",
                category: "Offset Printing",
                name: "Receipt Books",
                description: "Receipt Books Customized",
                made: "Customized",
                quantity: "Custom",
                details: ["BILLBOOK,  RECEIPT BOOK, CHALAN BOOK SERVICES"],
                img: [op004],
                price: "Custom"
            }
        ]
    },
    {
        category: "Wipes",
        item: {
            id: "wps001",
            category: "Wipes",
            name: "Wipes",
            description: "Wipes",
            made: "Made in China",
            quantity: "100 pcs",
            details: ["WIPES USED FOR HEAD CLEANING"],
            img: [wps001],
            price: 1500
        }
    },
    {
        category: "Eyelets",
        item: {
            id: "eye001",
            category: "Eyelets",
            name: "Eyelets",
            description: "Eyelets 26 number",
            made: "Made in Pakistan",
            quantity: "600 pcs",
            details: ["EYELETS USED FOR PANAFLEX HANGING"],
            img: [eye001],
            price: 800
        }
    }
]