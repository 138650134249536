import React from 'react'
import Marquee from 'react-fast-marquee'

import { Email, Location, Phone } from '../Components'


export const Header = () => {
    return (
        <header className='bg-[#E6E6E6] py-2'>
            <div className='flex justify-center xl:hidden p-sm'>
                <Marquee>
                    <div className='flex items-center gap-x-4 px-6'>
                        <Location fill='#333333' />
                        <p><span className='font-bold'>Karachi</span> (Nazimabad - Pakistan Chock), <span className='font-bold'>Lahore</span> (Sabzazar), <span className='font-bold'>Rawalpindi</span> (Commercial Market)</p>
                    </div>
                    <div className='flex items-center gap-x-4 px-6 border-x border-x-black'>
                        <Email fill='#333333' />
                        <p>contact@skprintingsolution.com</p>
                    </div>
                    <div className='flex items-center gap-x-4 px-6 border-r border-black'>
                        <Phone fill='#333333' />
                        <p>+92-310-280-6759</p>
                    </div>
                </Marquee>
            </div>

            <div className='hidden xl:flex justify-center divide-x divide-black p-sm'>
                <div className='flex items-center gap-x-4 pr-6'>
                    <Location fill='#333333' />
                    <p><span className='font-bold'>Karachi</span> (Nazimabad - Pakistan Chock), <span className='font-bold'>Lahore</span> (Sabzazar), <span className='font-bold'>Rawalpindi</span> (Commercial Market)</p>
                </div>
                <div className='flex items-center gap-x-4 px-6'>
                    <Email fill='#333333' />
                    <p>contact@skprintingsolution.com</p>
                </div>
                <div className='flex items-center gap-x-4 pl-6'>
                    <Phone fill='#333333' />
                    <p>+92-310-280-6759</p>
                </div>
            </div>
        </header>
    )
}