// src/utils/searchContent.js

import { products } from '../Data/Products';

export const searchContent = (query) => {
  if (!query.trim()) {
    return [];
  }

  const results = [];
  products.forEach(category => {
    if (category.items) {
      const res = category.items.filter(item => item.description.toLowerCase().includes(query.toLowerCase()))
      results.push(...res)
    } else {
      if (category.item.description.toLowerCase().includes(query.toLowerCase())) {
        results.push(category.item)
      }
    }
  });
  return results.length > 0 ? results : null;
};
