import React, { useEffect, useRef, useState } from 'react';

export const Cookies = () => {
    const cookie = useRef();
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const consent = localStorage.getItem('cookieConsent');
        if (!consent) {
            setTimeout(() => {
                setIsVisible(true);
                document.body.style.overflowY = "hidden";
            }, 1000);
        }
    }, []);

    const acceptCookies = () => {
        localStorage.setItem('cookieConsent', 'true');
        setIsVisible(false);
        document.body.style.overflowY = "auto";
    };

    return (
        isVisible && (
            <section ref={cookie} className='h-screen w-full bg-[#000000cc] fixed top-0 z-20 flex items-end'>
                <div className='slide-in-bottom bg-white w-full pb-20 sm:pb-0'>
                    <div className='px-5 py-9 space-y-6 sm:space-y-3 sm:w-[648px] xl:w-[900px] sm:mx-auto'>
                        <p className='text-2xl leading-[--base-height] font-semibold'>This website uses cookies</p>
                        <p className='text-lg'>
                            We use cookies to ensure the site functions correctly and to collect data to improve your experience. By continuing to use this site, you consent to our use of cookies as outlined in our <a className='underline cursor-pointer' href="/privacy-policy">Privacy Policy</a>.
                        </p>
                        <div className='sm:flex justify-end'>
                            <button className='button-black w-full sm:w-auto sm:ml-auto' onClick={acceptCookies}>Accept</button>
                        </div>
                    </div>
                </div>
            </section>
        )
    );
};
