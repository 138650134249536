import React from 'react'
import { Link } from 'react-router-dom';

import { logoFooter } from '../assets';
import { Location, Email, Phone } from '../Components';
import socialLinks from '../Data/SocialLinks';

export const Footer = () => {
    return (
        <footer className='bg-[--black-bg] divide-y divide-[#ffffff80]'>

            <section className='px-5 sm:px-[--padding-x-sm] xl:px-[--padding-x-lg] py-12 sm:py-14 xl:py-[--padding-y-lg] sm:grid grid-cols-7 xl:grid-cols-5 gap-x-6 xl:gap-x-8 gap-y-14 text-white center max-sm:space-y-9'>

                <Link to="/" className='sm:col-span-7 xl:col-span-2'>
                    <img src={logoFooter} alt='Logo - SK Printing' className='max-xl:mx-auto max-sm:h-20' />
                </Link>

                <div className='space-y-4 max-xl:col-span-2'>
                    <div className='font-semibold'>Navigation links</div>
                    <ul className='p-sm space-y-1'>
                        <li><Link to='/' className='hover:text-[--green]'>Home</Link></li>
                        <li><Link to='/about-us' className='hover:text-[--green]'>About Us</Link></li>
                        <li><Link to='/products' className='hover:text-[--green]'>Products</Link></li>
                        <li><Link to='/gallery' className='hover:text-[--green]'>Gallery</Link></li>
                        <li><Link to='/contact-us' className='hover:text-[--green]'>Contact Us</Link></li>
                    </ul>
                </div>

                <div className='space-y-4 max-xl:col-span-3'>
                    <div className='font-semibold'>Contact</div>
                    <div className='space-y-3 sm:space-y-4 p-sm'>

                        <div className='flex gap-x-[14px] items-start'>
                            <Location fill='white' />
                            <div className='space-y-2'>
                                <p>Address</p>

                                <div>
                                    <p className='font-bold'>Karachi</p>
                                    <p>Nazimabad - Pakistan Chowk</p>
                                </div>

                                <div>
                                    <p className='font-bold'>Lahore</p>
                                    <p>Sabzazar</p>
                                </div>

                                <div>
                                    <p className='font-bold'>Rawalpindi</p>
                                    <p>Commercial Market</p>
                                </div>
                            </div>
                        </div>

                        <div className='space-y-2'>
                            <div className='flex gap-x-[14px] items-center'>
                                <Email fill='white' />
                                <p>contact@skprintingsolution.com</p>
                            </div>

                            <div className='flex gap-x-[14px] items-center'>
                                <Phone fill='white' />
                                <p>+92-310-280-6759</p>
                            </div>
                        </div>

                    </div>
                </div>

                <div className='space-y-4 max-xl:col-span-2'>
                    <div className='font-semibold'>Follow Us</div>
                    <div className='flex'>
                        {socialLinks.map(social => (
                            <a key={social.site} href={social.link} title={social.site} target='_blank'
                                className='text-white hover:text-[--green] transition-colors duration-200'>
                                <social.icon className='size-8 fill-current' />
                            </a>
                        ))}
                    </div>
                </div>

            </section>


            <section className='p-sm text-white sm:text-center xl:text-left xl:flex justify-between space-y-6 sm:space-y-1 xl:space-y-0 pt-6 sm:pt-3 pb-12 sm:pb-14 px-5 sm:px-[--padding-x-sm] xl:px-[--padding-x-lg] center'>
                <p>Copyright © 2024 <span className='text-base leading-[--base-height] font-semibold'>SK Printing Solution</span> - All Rights Reserved.</p>
                <p>Design & Developed by<br className='sm:hidden' /> <a href='https://www.linkedin.com/company/techieonix/' className='text-base leading-[--base-height] font-semibold'>Techieonix International</a> | <a href='mailto:contact@techieonix.com'>contact@techieonix.com</a></p>
            </section>
        </footer>
    )
}