// Arrow.jsx
import React from 'react';

export const Arrow = ({ className }) => {
  return (
    <svg className={className} height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 18C16.7 18 16.5 17.9 16.3 17.7L6.3 7.7C5.9 7.3 5.9 6.7 6.3 6.3C6.7 5.9 7.3 5.9 7.7 6.3L17.7 16.3C18.1 16.7 18.1 17.3 17.7 17.7C17.5 17.9 17.3 18 17 18Z" fill="" />
      <path d="M17 18H8C7.4 18 7 17.6 7 17C7 16.4 7.4 16 8 16H16V8C16 7.4 16.4 7 17 7C17.6 7 18 7.4 18 8V17C18 17.6 17.6 18 17 18Z" fill="" />
    </svg>
  );
};