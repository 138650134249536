// TwitterIcon.jsx
import React from 'react';

export const TwitterIcon = ({ className }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      viewBox='0 0 32 32'
      fill='currentColor'
    >
      <path d="M5.1723 6.40251L13.5745 17.3433L5.12012 26.2383H7.02279L14.4247 18.4509L20.4045 26.2404H26.8801L18.0082 14.6812L25.8777 6.40039H23.975L17.158 13.5757L11.6479 6.40251H5.1723ZM7.97086 7.7664H10.9455L24.0816 24.8744H21.1091L7.97086 7.7664Z" />
    </svg>
  );
};