// YouTubeIcon.jsx
import React from 'react';

export const YouTubeIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      className={className}
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5894 7.62943C15.4513 7.60635 15.2672 7.60635 15.1291 7.62943C14.7839 7.69291 14.1741 7.91219 13.9497 8.65659C13.9497 8.65659 13.8979 9.02591 13.8979 9.18171V11.8131C13.8979 11.8131 13.9094 12.2632 14.1626 12.6556C14.3524 12.9557 14.6746 13.2153 15.2039 13.273C15.2729 13.2788 15.5031 13.2788 15.5894 13.2673C16.0841 13.198 16.3948 12.9441 16.5789 12.6556C16.8263 12.2632 16.8435 11.8131 16.8435 11.8131V9.18171C16.8435 9.02591 16.7918 8.65659 16.7918 8.65659C16.5559 7.90065 15.9288 7.68714 15.5894 7.62943ZM15.8367 9.18171V11.8131C15.8367 12.4479 15.365 12.3902 15.365 12.3902C15.365 12.3902 14.8414 12.4421 14.8414 11.8131V9.18171C14.8414 8.44308 15.365 8.49502 15.365 8.49502C15.8885 8.49502 15.8367 9.18171 15.8367 9.18171ZM11.9476 8.65659L11.1652 5.75977H10.0088L11.4241 10.18V13.1807H12.4711V10.18L13.8404 5.75977H12.7358L11.9476 8.65659ZM19.5648 7.65251V11.917C19.4037 12.2863 18.9895 12.3902 18.9895 12.3902C18.6213 12.3902 18.6731 12.1305 18.6731 12.1305V7.65251H17.6778V12.4421C17.6778 12.9672 17.9424 13.2846 18.4659 13.2846C18.9895 13.2846 19.5705 12.5979 19.5705 12.5979V13.2327H20.5658V7.65251H19.5648Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.7002 14.8893C21.4862 14.6758 20.1055 14.7277 15.7158 14.6758C12.7529 14.6758 11.2629 14.7277 11.0615 14.7277C9.1572 14.8374 7.83398 14.5719 7.35646 16.6378C7.1436 17.8034 7.04004 19.2922 7.04004 20.429C7.1436 22.8988 6.93648 25.8707 9.31254 26.0842C10.5322 26.1938 13.708 26.24 15.8021 26.24C18.6557 26.24 21.7566 26.1881 22.8152 25.9746C23.2812 25.8591 23.6897 25.3802 23.822 25.1782C24.4434 24.3473 24.4549 23.0893 24.5066 20.4002C24.3916 18.1266 24.5527 15.1547 22.7002 14.8893ZM12.1661 17.5438H10.9522V23.9664H9.83608V17.5438H8.67394V16.43H12.1661C12.1661 16.43 12.2179 17.5438 12.1661 17.5438ZM15.2901 24.0183H14.2833V23.3778C14.2833 23.3778 13.754 24.0703 13.1729 24.0703C12.5918 24.0703 12.379 23.6952 12.379 23.0085V18.392H13.3858V22.9046C13.3858 22.9046 13.3858 23.1181 13.6504 23.1181C13.9151 23.1181 14.2833 22.743 14.2833 22.6392V18.392H15.2901V24.0183ZM19.1562 23.0143C19.1562 23.0143 19.0469 24.076 18.414 24.076C17.7812 24.076 17.4072 23.701 17.2001 23.4413V24.0241H16.1933V16.482H17.2001V18.9229C17.2001 18.9229 17.7294 18.2824 18.2069 18.2824C18.6844 18.2824 19.162 18.5998 19.162 19.2922V23.0143H19.1562ZM22.9648 23.0143C22.8095 23.9722 21.6416 24.076 21.6416 24.076C19.9501 24.076 20.0019 22.4834 20.0019 22.4834V19.8289C20.0019 19.6154 20.1055 19.298 20.1055 19.298C20.2148 18.9806 20.3183 18.3401 21.5898 18.3401C23.0741 18.3401 23.0223 19.8289 23.0223 19.8289V21.3696H21.0087V22.4834C21.0087 22.8007 21.1641 23.1181 21.5898 23.1181C22.0155 23.1181 22.0155 22.4834 22.0155 22.4834V22.0563H23.0223C23.0166 22.0563 23.0684 22.316 22.9648 23.0143ZM22.0098 19.8231C22.0098 19.8231 21.958 19.2403 21.5323 19.2403C21.1065 19.2403 21.003 19.7193 21.003 19.8231V20.5156H22.0098V19.8231ZM17.7237 19.2403C17.459 19.2403 17.2462 19.4538 17.2462 19.4538V22.9046C17.4015 23.1181 17.7237 23.1181 17.7237 23.1181C18.1494 23.1181 18.1494 22.6392 18.1494 22.6392V19.8231C18.1494 19.4538 17.9883 19.2403 17.7237 19.2403Z"
      />
    </svg>
  );
};